//imports
import React, { useState, useEffect } from "react";
import style from "./css/Rankbadge.module.css";
import { auth, db } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { ref as refDb, get, set, onValue, update } from "firebase/database";
import SeasonRewards from "./SeasonRewards.js"; // Components
import Footer from "./Footer.js"; //Components


function Rankbadge(props){
  //variables and React hooks
  const rs = "₹";
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [userRuppee, setUserRuppee] = useState(0.0) 
  const [rank, setRank] = useState("Rank");
  
  //functions
  //
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (detectedUser) => {
      if(detectedUser){
        setUser(detectedUser);
      };
    });
  });
  //
  useEffect(() => {
    if(user){
      onValue(refDb(db, "user/" + user.uid), (snapshot) => {
        if(snapshot.exists()){
          setUserData(snapshot.val());
          if(snapshot.val().stars < 30){
            setRank("Bronze");
          }else if(snapshot.val().stars < 50){
            setRank("Silver");
          }else if(snapshot.val().stars < 100){
            setRank("Gold");
          }else{
            setRank("Diamond");
          };
        };
      })
    };
  }, [user]);
  //
  const goBack = () => {
    props.goToPage("Dashboard");
  };
  
  return(
    <>
    <div id={style.bg}>
    <div id={style.bg2}>
      {/*toolbar (start)*/}
     <div id={style.toolbar}>
     <div className={style.linear}>
     <i onClick={goBack} className="fa-solid fa-chevron-left"></i>
     <span>Ranks</span>
     {/*<img src="./images/ezexaheading3.png" id={style.logo}/>*/}
     </div>
      <div className={style.linear}>
      <div id={style.wallet} >
        {`${rs} ${userData.ruppee ? userData.ruppee.toFixed(1) : 0.0}`}
      </div>
      <div id={style.rankBadge}>
       <img src={
            (userData.stars < 30) ? "/images/bronzebadge.png" : 
            (userData.stars < 50) ? "/images/silverbadge.png" : 
            (userData.stars < 100) ? "/images/goldbadge.png" :
                  "/images/dimondbadge.png"
               } />
      </div>
     </div>
     </div>
     {/*toolbar (end)*/}
     <div id={style.ranksDiv}>
       <img src={
            (userData.stars < 30) ? "/images/bronzebadge.png" : 
            (userData.stars < 50) ? "/images/silverbadge.png" : 
            (userData.stars < 100) ? "/images/goldbadge.png" :
                  "/images/dimondbadge.png"
               } />
      <p id={style.p1}>{`${rank} (${userData.stars && userData.stars} stars)`}</p>
     </div>
     <div id={style.btnsDiv}>
      <button id={style.btn1}>
       Details
      </button>
      <button id={style.btn2}>
       Earn Exp
      </button>
     </div> 
     <div className={style.hl}></div>
     <div id={style.ranksViewDiv}>
      Rank Badges
      <div id={style.ranksView}></div>
     </div>
     <div className={style.hl}></div>
     <SeasonRewards />
    </div>
    <div className={style.hl}></div>
         <Footer />
    </div>
    </>
    );
};

export default Rankbadge;