//import
import React, { useState, useEffect, useRef } from "react";
import style from "./css/Refer.module.css";
import Lottie from "lottie-react"; 
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { auth } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import Toast from "./Toast.js"; // components

function Refer(props){
  //
  // variables 
  //
  const rs = "₹";
  const audioRef = useRef();
  const [sound, setSound] = useState(true);
  const audio = new Audio("/.wav");
  const [user, setUser] = useState(null);
  const [nav, setNav] = useState(1);
  const [toast, setToast] = useState(false);
  const [toastData, setToastData] =useState({});
  //
  const share = () => {
    if(navigator.share){
     navigator.share({
            title: `Earn on E-Zexa App`,
            text: `Hello Dear!
            
 https://www.ezexa.xyz CLICK HERE!!

I just wanted to share my experience with an amazing app called E-Zexa. I've been using it for a while now, and I'm earning a lot of money through it. The app offers a wide variety of ways to make money without any investment, which is why I highly recommend it.

E-Zexa lets you earn money by playing games, completing tasks, and much more. The best part is that the earnings are real and they get credited to your account quickly, usually within 24 hours. All you need to do is link your UPI ID for withdrawals, and you're good to go!

What makes E-Zexa even better is the fact that it's completely secure. You need to link your email to your account for added security, which makes sure everything is safe and sound.

Whether you're looking to make some extra cash in your free time or you want to seriously earn money online, E-Zexa is the perfect platform for you. The app is super user-friendly and offers tons of opportunities to earn.

So if you're looking for a legit and fun way to make money, visit E-Zexa today! Trust me, you'll love it just as much as I do. Don’t miss out – start earning now!
 
 ENTER THIS CODE: ${user?.uid.substring(0,8)}
`
    }).catch(() => {
      //
    });
    };
  };
  
  //functions
  
  const toastShow = () => {
    setToast(!toast);
  };
  
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (detectedUser) => {
      if(detectedUser){
        setUser(detectedUser);
      };
    });
    //
    if(audioRef.current){
      audioRef.current.play();
      const audioEnded = () => {
        setSound(false);
      };
      audioRef.current.addEventListener("ended", audioEnded);
      return() => {
        if(audioRef.current){
        audioRef.current.removeEventListener("ended", audioEnded);
      }};
    };
    
    return() => {
      unsubscribe();
    }
  }, []);
  
  // sound on Off 
  const soundHandle = () => {
   if(audioRef.current.paused && !sound){
     audioRef.current.play();
     setSound(true);
   } else{
     audioRef.current.pause();
     setSound(false);
   } ;
  }
  
  
  const changeNav = (e) => {
    setNav(e.target.id === "nav1" ? 1 : 2);
  };
  
  
  //copy code 
  const copyCode = () => {
    if(navigator.clipboard){
      navigator.clipboard.writeText(user?.uid.substring(0, 8)).then(() => {
        setToastData({
          color: "green",
          msg: "Copied!",
        });
        setToast(true);
      });
    };
  };
  //
  return(
    <>
     <div id={style.bg}>
       {/*header (start)*/}
        <div id={style.header}>
         <p><i className="fa-solid fa-chevron-left"></i>Refer And Earn</p>
        </div>
       {/*header (end)*/}
       <audio ref={audioRef} style={{display: "none"}} controls autoplay>
        <source src="/ezexaPlayerInvitaion.wav"/>
       </audio>
       {/*body (start)*/}
        <div id={style.body}>
        {/*navigation (start)*/}
        <div id={style.navigation}>
         <p onClick={changeNav}
            id="nav1"
            style={{borderBottom: nav == "1" && "2px solid #f2009e"}}
            className={style.navs}>
          Invite
         </p>
         <p onClick={changeNav}
            id="nav2"
            style={{borderBottom: nav == "2" && "2px solid #f2009e"}}
            className={style.navs}>
          Reffers
         </p>
        </div>
       {/*navigation (start)*/}
       { nav == "1" ? <div id={style.screen1}>
       <div className={style.hl}></div>
        <div id={style.lottieDiv}>
          <DotLottieReact
           src="https://lottie.host/f3b36006-7b06-4401-b753-af69abde2667/BUUUkonyF4.lottie"
           loop
           autoplay
         />
         </div>
         <h1 className={style.headings}>Invite & Get 2000 EXP</h1>
         <h1 className={style.headings2}>Share the code with your friends</h1> 
         <div className={style.linear}>
          <div onClick={copyCode} id={style.codeDiv}>
           <i className="fa-regular fa-copy"></i>
           {user?.uid.substring(0,8)}
          </div>
          </div>
          <div onClick={soundHandle} id={style.soundBtn}>
           <i className={!sound ? "fa fa-play" : "fa fa-pause"}></i>
          </div>
          <div id={style.bottomNav}>
          <div onClick={share} id={style.inviteBtn}>
           Invite
          </div>
          </div>
         </div>
         :
         <div id={style.screen2}>
          <div className={style.hl}></div>
          <div id={style.noDataDiv}>
           <img src="./images/noData.webp" id={style.noData} />
           <p>No Referal found</p>
           </div>
         </div> }
        </div>
       {/*body (end)*/}
     </div>
     { toast && <Toast msg={toastData.msg} color={toastData.color} toastShow={toastShow} /> }
    </>
    );
};
export default Refer;
