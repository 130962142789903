//imports 
import React, { useState, useEffect } from "react";
import style from  "./css/LobbyBoxes.module.css";
import { auth, db } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { set, get, ref as refDb, onValue } from "firebase/database";
import Toast from "./Toast.js"; // components
import Loading from "./Loading.js"; // components


function LobbyBoxes(props){
  //
  // variables and hooks
  //
  const rs = "₹";
  const [toast, setToast] = useState(false);
  const [toastData, setToastData] = useState({msg: "null", color: "black"});
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [userRuppee, setUserRuppee] = useState(0);
  //
  // Toast 
  //
  const toastShow = () => {
    setToast(!toast);
    setTimeout(function() {
      setToast(false);
    }, 5000);
  };
  //
  // function
  //
  useEffect(() => {
    const authListener = onAuthStateChanged(auth, (detectedUser) => {
      if(detectedUser){
        setUser(detectedUser);
        get(refDb(db, "user/" + detectedUser.uid + "/ruppee/")).then((userRuppee) => {
          setUserRuppee(userRuppee.val());
        }).catch((e) => {
          setToastData({
            color: "red",
            msg: e.message,
          });
          setToast(true);
        });
      };
    });
  }, []);
  //
  const enter = async (e) => {
    //games
    if(props.game == "scl"){
      ///
      //Skill crush game
    const entryFee = e.target.innerText.replace(rs, "")
    if(userRuppee >= entryFee){
      setLoading(true)
      await set(refDb(db, "user/" + user.uid + "/ruppee/"), userRuppee - entryFee);
      props.goToPage("SkillCrush", {
        entryFee: entryFee,
        userUid: user.uid,
        userRuppee: userRuppee,
      });
    }else{
      setToastData({
        color: "red",
        msg: "You Have not Money!",
      })
      setToast(true);
    };
    setLoading(false);
    ///
    //
  }else if(props.game == "tttr"){
    ///
    //tic tac toe game
   const entryFee = e.target.innerText.replace(rs, "")
    if(userRuppee >= entryFee){
      setLoading(true)
      await set(refDb(db, "user/" + user.uid + "/ruppee/"), userRuppee - entryFee);
      props.goToPage("TttGameMaking");
    }else{
      setToastData({
        color: "red",
        msg: "You Have not Money!",
      })
      setToast(true);
    };
    setLoading(false);
    //
  };
  //
  };
  //
  return(
    <>
     <div id={style.bg}>
      <div id={style.header}>
       {props.title}
      </div>
      <div id={style.body}>
       <div className={style.linears}>
        <p>Top Pool</p>
        <div className={style.btnShape}>
         {props.prize}
        </div>
       </div>
       <div className={style.linears}>
        <p>Entry Fee</p>
        <div onClick={enter} className={style.btnShape} id={style.btn}>
         {rs}{props.fee}
        </div>
       </div>
       </div>
     </div>
     { loading && <Loading /> }
     { toast && <Toast 
                 msg={toastData.msg}
                 color={toastData.color}
                 toastShow={toastShow}
                 /> }
    </>
    );
};
export default LobbyBoxes;