// imports
import React, { useState, useRef, useEffect } from "react";
import style from "./css/SkillCrush.module.css";
import { db } from "../firebase.js";
import { onValue, set, get, ref as refDb } from "firebase/database";
import boomLottie from "../assets/boom.json";
import rocketLottie from "../assets/rocket.json";
import Lottie from 'lottie-react';

function SkillCrush(props) {
 //
 // variables and hooks 
 //
 const rs = "₹";
 const [userRuppee, setUserRuppee] = useState(props.gameItems.userRuppee);
 const [animation, setAnimation] = useState(rocketLottie);
 const [score, setScore] = useState(0.1);
 const [duration, setDuration] = useState(0);
 const [btn, setBtn] = useState(true);
 const timmer = useRef(null);
 const timmer2 = useRef(null);
 let [scoreStop, setScoreStop] = useState(false);
 const buttonRef = useRef(null);
 const newDate = new Date();
  const date = `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getYear()}`;
 //
 useEffect(() => {
  if(props.gameItems.userRuppee >= 100){
   setDuration(Math.floor(Math.random() * 3 + 1));
  }else{
    if(props.gameItems.entryFee == 10){
      setDuration(Math.floor(Math.random() * 6 + 1));
    }else{
      setDuration(Math.floor(Math.random() * 10 + 1));
    };
  };
 }, [props.gameItems.userRuppee, props.gameItems.entryFee]);
 
 //
 // function 
 //
 const goBack = () => {
      props.goToPage("SkillCrushLobby");
    };
    //
 const timmerFun = (dur) => {
   clearTimeout(timmer.current);
   clearTimeout(timmer2.current);
   timmer.current = setTimeout(function() {
       setBtn(false);
       setAnimation(boomLottie);
       setScoreStop(true);
     }, dur);
   timmer2.current = setTimeout(function() {
      goBack();
     }, dur + 1500);
 };
 //
 useEffect(()=> {
   if(duration != 0){
   const durationMap = {
     1: 1000,
     2: 1200,
     3: 1400,
     4: 2600,
     5: 3200,
     6: 4500,
     7: 7777,
     8: Math.floor(Math.random() * (8000 - 4000) + 4000),
     9: Math.floor(Math.random() * (12000 - 8000) + 8000),
     10: Math.floor(Math.random() * (18000 - 10000) + 10000),
   };
   const delay = durationMap[duration];
   timmerFun(delay);
  };
 }, [duration]);
 //
 useEffect(() => {
   onValue(refDb(db, "user/" + props.gameItems.userUid + "/ruppee/"), (snapshot) => {
     setUserRuppee(Math.round(snapshot.val() * 10) / 10);
   });
   const scoreUpdate = setInterval(function() {
     if(!scoreStop){
   setScore(preScore => Math.round((preScore + 0.1) * 10) / 10

 )}}, 200);
 
 return () => {
   clearInterval(scoreUpdate)
   };
 },[scoreStop, props.gameItems.userUid]);
 //
 
 //cashOut 
 //
 const cashOut = async () => {
   clearTimeout(timmer.current);
   clearTimeout(timmer2.current);
   const earn = score * props.gameItems.entryFee;
   goBack();
   await set(refDb(db, "user/" + props.gameItems.userUid + "/ruppee/"), userRuppee + earn);
   get(refDb(db, "user/" + props.gameItems.userUid + "/exp/")).then((snapshot) => {
     set(refDb(db, "user/" + props.gameItems.userUid + "/exp/"), snapshot.val() + 70);
   });
   get(refDb(db, "user/" + props.gameItems.userUid + "/dailyMissons/task1/")).then((snapshot) => {
       if(snapshot.val().claimed != date){
         set(refDb(db,"user/" + props.gameItems.userUid + "/dailyMissons/task1/"), {
             ...snapshot.val(),
             date: date,
             track: snapshot.val().date != date ? 1 : snapshot.val().track + 1,
         });
       };
      });
 };
 //
 return(
   <>
    <div id={style.bg}>
      <div id={style.header}>
        <div id={style.header}>
        <div id={style.headerBtns}>
          <i className="fa-solid fa-chevron-left" ></i>
          <i className="fa-solid fa-question"></i>
        </div>
          <div id={style.wallet}>
            {rs}{userRuppee.toFixed(1)}
          </div>
         </div>
      </div>
      <div id={style.notifier}>
       <div id={style.scroll}>
        <div className={style.notifierBoxes}>
        Most
        </div>
        <div className={style.notifierBoxes}>
        5.8x
        </div>
        <div className={style.notifierBoxes}>
        7.6x
        </div>
        <div className={style.notifierBoxes}>
        0.7x
        </div>
        <div className={style.notifierBoxes}>
        8.2x
        </div>
        <div className={style.notifierBoxes}>
        16.5x
        </div>
       </div>
      </div>
      <div id={style.body}>
        <Lottie id={style.rocket} animationData={animation} loop={true} autoplay={true} />
        <div id={style.scoreDiv}>
          {score}x
        </div>
      </div>
      <div id={style.footer}>
        <div id={style.winningCash}>
         {rs}{score * props.gameItems.entryFee}
        </div>
        {btn ? <button ref={buttonRef} id={style.button} onClick={cashOut}>
         CRUSH
        </button> : <button ref={buttonRef} id={style.button2}>
         CRUSH
        </button>}
      </div>
    </div>
   </>
   )
};

export default SkillCrush;