// imports
import React from "react";
import style from "./css/HomeSplash.module.css";

function HomeSplash() {
  return(
    <>
     <div id={style.bg}>
  {/**/}
      <div className={style.scrollDiv}>
       <div className={style.scroll}>
         <div className={style.circleBg}>
          <div className={style.circle}>
          </div>
         <div className={style.text}></div>
        </div>
        <div className={style.circleBg}>
         <div className={style.circle}>
         </div>
         <div className={style.text}></div>
        </div>
        <div className={style.circleBg}>
         <div className={style.circle}>
         </div>
         <div className={style.text}></div>
        </div>
        <div className={style.circleBg}>
         <div className={style.circle}>
         </div>
         <div className={style.text}></div>
        </div>
        <div className={style.circleBg}>
         <div className={style.circle}>
         </div>
         <div className={style.text}></div>
        </div>
       </div>
      </div>
      <div id={style.spacialDiv}>
{/**/}
        <div className={style.spacialBoxes}>
        </div>
        <div className={style.spacialBoxes}>
        </div>
       </div>
{/**/}
       <div className={style.headingDiv}>
        <div className={style.heading}></div>
        <div className={style.heading}></div>
       </div>
{/**/}
       <div id={style.scrollDiv}>
          <div className={style.scroll}>
            <div className={style.squreBg}>
              <div className={style.squre}>
              </div>
              <div className={style.text}></div>
          </div>
          <div className={style.squreBg}>
            <div className={style.squre}>
            </div>
            <div className={style.text}></div>
          </div>
          <div className={style.squreBg}>
           <div className={style.squre}>
            </div>
           <div className={style.text}></div>
         </div>
         <div className={style.squreBg}>
           <div className={style.squre}>
           </div>
           <div className={style.text}></div>
         </div>
         <div className={style.squreBg}>
           <div className={style.squre}>
           </div>
           <div className={style.text}></div>
         </div>
          </div>
       </div>
 {/**/}
       <div className={style.headingDiv}>
        <div className={style.heading}></div>
        <div className={style.heading}></div>
       </div>
 {/**/}
       <div id={style.scrollDiv}>
          <div className={style.scroll}>
            <div className={style.squreBg}>
              <div className={style.squre}>
              </div>
              <div className={style.text}></div>
          </div>
          <div className={style.squreBg}>
            <div className={style.squre}>
            </div>
            <div className={style.text}></div>
          </div>
          <div className={style.squreBg}>
           <div className={style.squre}>
            </div>
           <div className={style.text}></div>
         </div>
         <div className={style.squreBg}>
           <div className={style.squre}>
           </div>
           <div className={style.text}></div>
         </div>
         <div className={style.squreBg}>
           <div className={style.squre}>
           </div>
           <div className={style.text}></div>
         </div>
          </div>
       </div>
  {/**/}
     </div>
    </>
    );
};
export default HomeSplash;