// imports
import React, { useState, useEffect, useRef } from "react";
import style from "./css/Report.module.css";
import { auth, db } from "../firebase.js";
import { set, ref as refDb, push } from "firebase/database";
import { onAuthStateChanged } from "firebase/auth"; 
import Loading from "./Loading.js"; //components
import Toast from "./Toast.js" //components
function Report(props){
  //
  //variable and React hooks
  const [user, setUser] = useState({email: "ezexauser@email.com"});
  const textareaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastData, setToastData] = useState({});
  //functions
  //
  const toastShow = () => {
    setToast(!toast);
    setTimeout(function() {
      setToast(false);
    }, 5000);
  };
  //
  //
  useEffect(() => {
    const authListener = onAuthStateChanged(auth, (detectedUser) => {
      if(detectedUser){
        setUser(detectedUser);
      };
    });
    return() => authListener();
  }, [])
  //
  const goBack = () => {
    props.report(false);
  };
  //
  const send = async () => {
    if(textareaRef.current.value != ""){
      setLoading(true);
      await push(refDb(db, "reports/"), {
        from: user.email,
        report: textareaRef.current.value,
        date: new Date().toString(),
      });
      setLoading(false);
      textareaRef.current.value = "";
      setToastData({
        color: "green",
        msg: "Report sended!"
      });
      toastShow();
    };
  };
  //
  return(
    <>
      <div id={style.bg}>
       <div id={style.box}>
         <div id={style.header}>
           <p>Report</p> <i onClick={goBack} className="fa-solid fa-xmark"></i>
         </div>
         <div id={style.body}>
          <div>
           from: {user.email}
          </div>
          <textarea ref={textareaRef} placeholder="Describe your Report"/>
         </div>
         <div id={style.footer}>
           <button onClick={send}> send </button>
         </div>
       </div>
      </div>
      { toast && <Toast msg={toastData.msg} color={toastData.color} toastShow={toastShow} /> }
      { loading && <Loading /> }
    </>
    );
};

export default Report;