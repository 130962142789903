  // imports 
  import React, { useState, useEffect, useRef } from "react";
  import style from "./css/TicTacToeLobby.module.css";
  import banner from "../assets/skillcrushbanner.jpg";
  import LobbyBoxes from "./LobbyBoxes.js"; // Components
  import { auth, db } from "../firebase.js";
  import { onAuthStateChanged } from "firebase/auth";
  import { get, set, ref as refDb, onValue , remove, push, update } from "firebase/database";
  import Loading from "./Loading.js"; //Components
  import Toast from "./Toast.js"; // Components
  import Footer from "./Footer.js";
  
  function TicTacToeLobby(props) {
    //
    // variables and react hooks
    //
    const rs = "₹";
    const [user, setUser] =useState(null);
    const [userRuppee, setUserRuppee] = useState(0);
    const roomkeyRef = useRef(null);
    const [selectedAmount, setSelectedAmount] = useState(1);
    const [firstPlayer, setFirstPlayer] = useState("you")
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);
    const [toastData, setToastData] = useState({mgs: "null", color: "black"});
    const [userData, setUserData] = useState({});
    //
    //
     //function after use Detected
  //
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, async (detectedUser) => {
        if(detectedUser){
         setUser(detectedUser);
       };
     });
     return() => {
       unsubscribe();
     };
     });
     //
     useEffect(() => {
       if(user){
         const unsubscribe = onValue(refDb(db, "user/" + user.uid), (snapshot) => {
           setUserData(snapshot.val());
           setUserRuppee(snapshot.val().ruppee);
         });
       };
     }, [user]);
    //toastShow
     const toastShow = () => {
       setToast(!toast);
       setTimeout(function() {
         setToast(false);
       }, 5000);
     };
  //
    const goBack = () => {
      props.goToPage("Dashboard");
    };
    //
    // incease 1 when clicked on + button
    const increaseAmount = () => {
      setSelectedAmount(selectedAmount < 10 ? selectedAmount + 1 : selectedAmount);
    };
    // decrease 1 when clicked on - button 
    const decreaseAmount = () => {
      setSelectedAmount(selectedAmount > 1 ? selectedAmount - 1 : selectedAmount);
    };
    const firstPlayerHandle = (e) => {
     setFirstPlayer(e.target.value);
    };
    //
    //Play with random people [start]
    const start = async() => {
      if(userRuppee >= selectedAmount){
        //if any player already in waitinglist with same selected amount then matchint both players 
        await get(refDb(db, 'waittingLists/')).then((snapshot) => {
          if(snapshot.exists()){
            const waittingLists = Object.entries(snapshot.val());
            let amountFound = false;
            console.log(waittingLists)
            for(let i=0; i < waittingLists.length; i++){
              if(waittingLists[i][1].ammount == selectedAmount){
                setLoading(true);
                joinRoom(waittingLists[i][1].roomKey);
                remove(refDb(db, 'waittingLists/' + waittingLists[i][0]));
                amountFound = true;
                break;
              };
            };
            if(!amountFound){
             createRoom("waittingLists");
            };
          }else{
            createRoom("waittingLists");
          };
        });
      }else{
        setToastData({
         color: 'red',
         msg: 'You have not money!',
       });
       toastShow();
      };
    };
    // join btn click handle 
    const join = () => {
      joinRoom("joinWithRoomKey");
    };
    //create btn click handle 
    const create = () => {
      createRoom("createRoomWithCustom");
    };
    //
    //Join in Room Function 
    const joinRoom = (roomKeyProp) => {
      //here is logic to join in Room with room key 
      //
      if(roomkeyRef.current.value != "" || roomKeyProp){
        const roomKey = roomKeyProp == 'joinWithRoomKey' ? roomkeyRef.current.value : roomKeyProp ;
        setLoading(true);
        //searching.. RoomKey exists??
        get(refDb(db, "room/" + roomKey)).then((snapshot) => {
          setLoading(false);
          if(snapshot.exists()){
            //if single player in room then player enter in room else alert
            if(snapshot.val().player2 == ""){
               if(userRuppee >= snapshot.val().ammount){
               set(refDb(db, 'user/' + user.uid + "/ruppee/"), userRuppee - snapshot.val().ammount);
               props.goToPage("TttGameMaking", roomKey); 
               }else{
              //show Toast You have'nt money
               setLoading(false);
               setToastData({
                color: "red",
                msg: "You have not money!",
               });
              toastShow();
            //
               };
            }else{
              //show Toast Room are full
               setLoading(false);
               setToastData({
                color: "red",
                msg: "Room are full!",
               });
              toastShow();
            //
            }
          }else{
            //if room not found 
            setLoading(false);
            setToastData({
              color: "red",
              msg: "Room is't found!",
            });
            toastShow();
            //
          };
        });
      };
      //
    };
   //Create Room Function
   const createRoom = (roomKeyProp) => {
     if(userRuppee >= selectedAmount){
       setLoading(true);
       set(refDb(db, "user/" + user.uid + "/ruppee/"), userRuppee - selectedAmount).then(() => {
         //here is logic to unique Room key 
         const newDate = new Date();
         //create new Date() &
         const date = 
         `${newDate.getDate()}${newDate.getMonth() + 1}${newDate.getYear()}${newDate.getHours()}${newDate.getMinutes()}${newDate.getSeconds()}`;
         // and now generete Random Number and some it 
         const randomNumber = 
           Math.floor(Math.random() * 10000);
         // And here are RoomKey 
         const roomkey = (Number(date) * randomNumber).toString().substring(0, 10);
         //
         //if function called from play with random people [start]
         if(roomKeyProp && roomKeyProp == "waittingLists"){
           push(refDb(db, "waittingLists/"), {
             player1: user.uid,
             ammount: selectedAmount,
             roomKey: roomkey,
           });
         };
         //randomFirstPlayer
         const randomNumber1or2 = Math.floor(Math.random() * 2 + 1);
         const randomFirstPlayer = randomNumber1or2 == 1 ? "player1" : "player2"; 
         //create Room in firebase real time database
         set(refDb(db, "room/" + roomkey), {
           player1: user.uid,
           player1Data: userData,
           player2: "",
           ammount: selectedAmount,
           curPlayer: roomKeyProp === "createRoomWithCustom" ? firstPlayer : randomFirstPlayer,
           winner: "none",
           board: ["", "", "", "", "", "", "", "", ""],
           status: "connecting",
         });
         //close loading 
         setLoading(false);
         //go to match makking
         props.goToPage("TttGameMaking", roomkey);
         return roomkey;
       });
     }else{
       setToastData({
         color: 'red',
         msg: 'You have not money!',
       });
       toastShow()
     };
   };
    return(
      <>
       <div id={style.bg}>
         <div id={style.banner}>
         <div id={style.header}>
          <i className="fa-solid fa-chevron-left" onClick={goBack}></i>
          <div id={style.wallet}>
            {rs}{userRuppee.toFixed(1)}
          </div>
         </div>
         </div>
         <div id={style.linear}>
         <h1>
           Room
         </h1>
         <div id={style.linear2}>
         
         <div className={style.boxes}>
            <div className={style.linear2Header}>
              Play with Random People
            </div>
            <div className={style.content}>
               <p>Select Amount</p>
              <div className={style.amountSelection}>
                <div className={style.amountInput}>
                 {selectedAmount}
                </div>
                <div>
                <button className={style.increaseButton} onClick={increaseAmount}>
                   +
                </button>
                <button className={style.decreaseButton} onClick={decreaseAmount}>
                   -
                </button>
                </div>
              </div>
              <button className={style.mainBtn}
                onClick={start}>START</button>
            </div>
          </div>
          
          <div className={style.boxes}>
            <div className={style.linear2Header}>
              Join Room
            </div>
            <div className={style.content}>
              <p>Enter Roomkey</p>
              <input ref={roomkeyRef} type="text" id={style.joinInput} placeholder="Enter 10 digit Roomkey here" maxLength={10}/>
              <button className={style.mainBtn}
                onClick={join}>JOIN</button>
            </div>
          </div>
          
          <div className={style.boxes}>
            <div className={style.linear2Header}>
              Create Room
            </div>
            <div className={style.content}>
              <p>Select Amount</p>
              <div className={style.amountSelection}>
                <div className={style.amountInput}>
                 {selectedAmount}
                </div>
                <div>
                <button className={style.increaseButton} onClick={increaseAmount}>
                   +
                </button>
                <button className={style.decreaseButton} onClick={decreaseAmount}>
                   -
                </button>
                </div>
              </div>
              <div id={style.firstPlayerSelection}>
                <p>Select first player</p>
                <div>
                <div>
                <input type="radio" 
                       value={user ? user.uid : "player1"}
                       checked={firstPlayer === "you" || firstPlayer === user?.uid}
                      onChange={firstPlayerHandle}
                       />
                       You
                </div>
                <div>
                <input type ="radio" 
                       value="player2"
                       checked={firstPlayer === "player2"}
                       onChange={firstPlayerHandle}
                       />
                       Opponent
                 </div>
                  </div>
                  <button className={style.mainBtn}
                     onClick={create}>Create</button>
              </div>
            </div>
          </div>
         </div>
         <Footer />
        </div>
       </div>
       { loading && <Loading/> }
       { toast && <Toast color={toastData.color} msg={toastData.msg} />}
      </>
      );
  };
  export default TicTacToeLobby;
