//import
import React, { useState, useEffect } from "react";
import style from "./css/Settings.module.css";
import { auth, db } from "../firebase.js";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { onValue, get, set, ref as refDb } from "firebase/database";
import level from "./level.js"; //components
import Loading from "./Loading.js"; //components
import SettingsSplash from "./SettingsSplash.js"; //components
import Confirm from "./Confirm.js"; //components
import { useNavigate } from "react-router-dom";
import Report from "./Report.js";
import Footer from "./Footer.js";
//components
import EditProfile from "./EditProfile.js"; //components

function Settings(props){
  //
  // variables and react hooks
  //
  const rs = "₹";
  const navigate = useNavigate();
  const [userRuppee, setUserRuppee] = useState(0);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({})
  const [userLevel, setUserLevel] = useState(0);
  const [loading, setLoading] = useState(true);
  const [settingsSplash, setSettingsSplash] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [confirmVal, setConfirmVal] = useState(false);
  const [confirmData, setConfirmData] = useState({});
  const [report, setReport] = useState(false);
  const [userExp, setUserExp] = useState(0);
  const [stars, setStars] = useState(0)
  const [rank, setRank] = useState({
    img: "/images/bronzebadge.png",
    name: "Bronze",
  });
  const [editProfileView, setEditProfileView] = useState(false);
  //function
  //
  //
  //Rank badge Function 
  const rankBadge = (star) => {
    if(star < 30){
      setRank({
        img: "/images/bronzebadge.png",
        name: "Bronze",
      });
    }else if(star < 50){
      setRank({
        img: "/images/silverbadge.png",
        name: "Silver",
      });
    }else if(star < 100){
      setRank({
        img: "/images/goldbadge.png",
        name: "Gold",
      });
    }else if(star > 100){
      setRank({
        img: "/images/dimondbadge.png",
        name: "Diamond",
      });
    }
  };
  //
  useEffect(() => {
    const authListener = onAuthStateChanged(auth, (detectedUser) => {
      if(detectedUser){
        //User
        setUser(detectedUser);
        setLoading(false);
        //
      };
    });
  }, []);
  //
  useEffect(() => {
    if(user){
      const unsubscribe = onValue(refDb(db, "user/" + user.uid), (snapshot) => {
        if(snapshot.exists()){
          setUserLevel(level(snapshot.val().exp));
          setUserRuppee(snapshot.val().ruppee);
          setStars(snapshot.val().stars);
          rankBadge(snapshot.val().stars);
          setUserData(snapshot.val());
          setUserExp(snapshot.val().exp);
        };
      });
    };
  }, [user])
  //
  useEffect(() => {
    if(userLevel != 0){
      setSettingsSplash(false);
    };
  }, [userLevel])
  //
  useEffect(() => {
    if(confirmVal){
      setLoading(true);
      signOut(auth);
      setLoading(false);
      props.goToPage("Signin");
      setConfirmVal(false);
    };
  }, [confirmVal])
  ///
  const logOut = () => {
    setConfirmVal(false);
    setConfirm(true);
    setConfirmData({
      title: "LogOut?",
      msg: "Do you want to LogOut This Account?",
    });
  };
  //
  const changePage = (e) => {
    navigate(`/${e.currentTarget.getAttribute("data-page") }`);
  };
  //
  const reportTrue = () => {
    setReport(true);
  };
  //
  const editProfileViewTrue = () => {
    setEditProfileView(true);
  };
  
  return(
    <>
     <div id={style.bg}>
       <div id={style.bg2}>
       {/*header (start)*/}
        <div id={style.header}>
         <p><i className="fa-solid fa-chevron-left"></i>Settings</p>
         <div id={style.wallet} >
             <div>
             {`${rs} ${userRuppee ? userRuppee.toFixed(1) : 0}`}
             </div>
             <i className="fa-solid fa-wallet"></i>
             </div>       
        </div>
       {/*header (end)*/}
       {/*body (start)*/}
       <div style={{margin: 0}} className={style.hl}></div>
        { settingsSplash ? <SettingsSplash /> : <div id={style.body}>
          {/*propfile (start)*/}
          <div id={style.profile}>
           <div id={style.profileDiv}>
             <div id={style.dpDiv}>
               <div style={{backgroundImage: `url(${userData?.profilePicture})`}} id={style.userDp}>
               </div>
               <p>{userLevel}</p>
             </div>
             <div id={style.profileDetails}>
             <h1>{userData?.name}</h1>
             <h2>{`${userExp} EXP`}</h2>
             </div>
           </div>
           <div className={style.gap}></div>
           <div id={style.badgeDiv}>
            <img src={rank.img}/>
            <p> {rank.name} </p>
            <div>
              <i className="fa-solid fa-star"></i><p>{stars}</p>
            </div>
           </div>
           </div>
              <div id={style.btnsDiv}>
              <button onClick={logOut} id={style.btn1}>
               Logout
              </button>
              <button onClick={editProfileViewTrue} id={style.btn2}>
               Edit profile
              </button>
              </div>    
              
          {/*propfile (end)*/}
        {/*Settings (start)*/}
          <div id={style.settingsDiv}>
            <div className={style.headings}>
              In App
            </div>
     {/**/}
            <div className={style.settingOptions}>
            <div>
              <i className="fa-solid fa-money-check"></i>
              Withdrow Money
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
     {/**/}
            <div className={style.settingOptions}>
            <div>
              <i className="fa-solid fa-headset"></i>
              Customer Care
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
    {/**/}
            <div className={style.settingOptions} onClick={logOut}>
            <div>
              <i className="fa-solid fa-right-from-bracket"></i>
              LogOut
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
            
    {/**/}
            <div className={style.headings}>
              About App
            </div>
    {/**/}
            <div className={style.settingOptions} data-page="TermsAndConditions" onClick={changePage}>
            <div>
              <i className="fa-solid fa-file-lines"></i>
              Teams And Conditions
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
    {/**/}
            <div className={style.settingOptions} data-page="PrivacyPolicy" onClick={changePage}>
            <div>
              <i className="fa-solid fa-shield-halved"></i>
              Privacy Policy
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
    {/**/}
           <div className={style.settingOptions} data-page="About" onClick={changePage}>
            <div>
              <i className="fa-solid fa-circle-info"></i>
              About Us
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
    {/**/}
           <div className={style.settingOptions}
           onClick={reportTrue}>
            <div>
              <i className="fa-solid fa-bug"></i>
              Report for Bugs
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
    {/**/}
          </div>
          </div>}
        {/*Settings (end)*/}
       {/*body (end)*/} 
       <Footer />
     </div>
     </div>
     { editProfileView && <EditProfile editProfileView={setEditProfileView}/> }
     { report && <Report report={setReport} /> }
     { loading && <Loading /> }
     { confirm && <Confirm 
      confirm={setConfirm} 
      confirmVal={setConfirmVal}
      title={confirmData.title}
      msg={confirmData.msg}/> }
    </>
    );
};
export default Settings;