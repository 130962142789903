import React, { useState, useRef, useEffect } from "react";
import style from "./css/Leaderboard.module.css";
import { auth, db } from "../firebase.js";
import { query, ref as refDb, orderByChild, onValue } from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";
import SeasonRewards from "./SeasonRewards.js"; //component
import level from "./level.js"; //components

function Leaderboard(props) {
  // React hook for managing state
  const rs = "₹";
  const [userRuppee, setUserRuppee] = useState(0);
  const [lists, setLists] = useState([]);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [listview, setListview] = useState("all");
  const [headingBackgound, setHeadingBackground] = useState({all:{
          background: "linear-gradient(180deg, #e2009fd2, #e2009f)",
          color: "#ffffff",
          },
        you: {
          background: "transparent",
          color: "#e2009f",
          },});
          
          
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (detectedUser) => {
      if (detectedUser) {
        // Reference to the database for user stars
        setUser(detectedUser);
        
        const listRef = query(refDb(db, 'user/'), orderByChild(`/stars/`));
        
        // Fetch data and sort it
        const unsubscribe2 = onValue(listRef, (snapshot) => {
          if (snapshot.exists()) {
            const usersArray = Object.keys(snapshot.val())
              .map((key) => ({
                data: snapshot.val()[key],
                stars: snapshot.val()[key].stars,
                key: key,
              }))
              .sort((a, b) => b.stars - a.stars); // Sort in descending order
            setLists(usersArray);
          }
        });

        // Cleanup on component unmount
        return () => unsubscribe2();
      }
    });

    // Cleanup on component unmount
    return () => unsubscribe();
  }, []);
  
  useEffect(() => {
    if(user){
      onValue(refDb(db, "user/" + user.uid), (snapshot) => {
        if(snapshot.exists()){
          setUserData(snapshot.val());
        };
      });
    };
  }, [user]);
  //
  
  //
  const headingClick = (e) => {
    if(e.currentTarget.id == "all"){
      setListview("all");
      setHeadingBackground({
        all:{
          background: "linear-gradient(180deg, #e2009fd2, #e2009f)",
          color: "#ffffff",
          },
        you: {
          background: "transparent",
          color: "#e2009f",
          },
      });
    }else{
      setListview("you");
      setHeadingBackground({
        you:{
          background: "linear-gradient(180deg, #e2009fd2, #e2009f)",
          color: "#ffffff",
          },
        all: {
          background: "transparent",
          color: "#e2009f",
          },
      });
    }
  }
  

  return (
    <>
      <div id={style.bg}>
       <div id={style.header}>
        <p><i className="fa-solid fa-chevron-left"></i>Season Firts Rankboard</p>
       </div>
        <div id={style.body}>
        <div id={style.banner}>
         <div className={style.bannerDivs} id={style.secondRankDiv}>
         <div className={style.top3PlyersProfileContainer}>
            <div id={style.secondRankProfilePicture} style={{backgroundImage: `url(${lists[1]?.data?.profilePicture ? lists[1]?.data?.profilePicture : "/images/user.png"})`}} className={style.listProfilePicture}>
            </div>
            <p>#2</p>
          </div>
           <span>{lists[1]?.data?.name}</span>
           <span>{lists[1]?.data?.stars} stars</span>
         </div>
         <div className={style.bannerDivs} id={style.firstRankDiv}>
         <div className={style.top3PlyersProfileContainer}>
           <div id={style.firstRankProfilePicture} style={{backgroundImage: `url(${lists[0]?.data?.profilePicture ? lists[0]?.data?.profilePicture : "/images/user.png"})`}} className={style.listProfilePicture}>
           </div>
           <p>#1</p>
        </div>
           <span>{lists[0]?.data?.name}</span>
           <span>{lists[0]?.data?.stars} stars</span>
         </div> 
         <div className={style.bannerDivs} id={style.thirdRankDiv}>
         <div className={style.top3PlyersProfileContainer}>
           <div id={style.thirdRankProfilePicture} style={{backgroundImage: `url(${lists[2]?.data?.profilePicture ? lists[2]?.data?.profilePicture : "/images/user.png"})`}} className={style.listProfilePicture}>
           </div>
           <p>#3</p>
          </div>
           <span>{lists[2]?.data?.name}</span>
           <span>{lists[2]?.data?.stars} stars</span>
         </div>
        </div>
          <div id={style.listview}>
          <div className={style.headingDiv}>
           <div id={style.heading}>
             <p  style={headingBackgound.all} id="all" onClick={headingClick} className={style.heading1}>
                All
             </p>
              <p  style={headingBackgound.you} id="you" onClick={headingClick} className={style.heading2}>
               You
             </p>
          </div>
         </div>

   {lists.map(({ data, stars, key }, index) => {
    if (listview === "you" && key !== user?.uid) return null;
    return (
      <React.Fragment key={key}>
        <div className={style.lists}>
          <p className={style.listsRank}>{index + 1}</p>
          <div style={{backgroundImage: `url(${data.profilePicture ? data.profilePicture : "/images/user.png"})`}} className={style.listProfilePicture}>
          <p>{level(data?.exp)}</p>
         </div>
          <p className={style.userName}>{data?.name || "Unknown User"}</p>
          <div className={style.space}></div>
          <div className={style.rankDiv}>
            <img
              className={style.rankImg}
              src={
                stars < 30
                  ? "/images/bronzebadge.png"
                  : stars < 50
                  ? "/images/silverbadge.png"
                  : stars < 100
                  ? "/images/goldbadge.png"
                  : "/images/dimondbadge.png"
              }
              alt="Badge"
            />
            <div>
              <i className="fa-solid fa-star"></i>
              <p>{stars}</p>
            </div>
          </div>
          </div>
          <div style={{display: listview === "all" ? "flex" : "none"}} className={style.hl}></div>
          </React.Fragment>
           );
         })}
       </div>
        </div>
      </div>
    </>
  );
}

export default Leaderboard;