//imports  
import React, { useState, useEffect } from "react";
import style from "./css/Drower.module.css";
import { auth, db } from "../firebase.js";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { onValue, ref as refDb } from "firebase/database";
import Confirm from "./Confirm.js"; //components
import Loading from "./Loading.js"; //components
import level from "./level.js"; //components
import { useNavigate } from "react-router-dom";
import SliderNav from "./SliderNav.js"; //components

function Drower(props){
  //variables and React hooks
  //
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [confirmVal, setConfirmVal] = useState(false);
  const [confirmData, setConfirmData] = useState({});
  const [loading, setLoading] = useState(false);
  const [splash, setSplash] = useState(true);
  const navigate = useNavigate();
  
  //functions
  //
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (detectedUser) => {
      if(detectedUser){
        setUser(detectedUser);
      };
    });
     return() => unsubscribe();
  }, []);
  
  useEffect(() => {
    if(user){
      const unsubscribe = onValue(refDb(db, "user/" + user.uid), (snapshot) => {
        if(snapshot.exists()){
          setUserData(snapshot.val());
          setSplash(false);
        };
      });
      return() => unsubscribe();
    };
  }, [user]);
  
  //function for closing the drower
  const drowerClose = () => {
    props.drowerShow();
  };
  //logOut
  useEffect(() => {
    if(confirmVal){
      setLoading(true);
      signOut(auth);
      setLoading(false);
      props.goToPage("Signin");
      setConfirmVal(false);
    };
  }, [confirmVal])
  ///
  const logOut = () => {
    setConfirmVal(false);
    setConfirm(true);
    setConfirmData({
      title: "LogOut?",
      msg: "Do you want to LogOut This Account?",
    });
  };
  
  //change page 
  const changePage = (e) => {
    navigate(`/${e.currentTarget.getAttribute("data-page") }`);
  };
  
  //return
  return(
    <>
     <div id={style.bg}>
{/**/}
      { splash ?
      <div className={style.drowerBox}>
{/**/}
       <div style={{marginTop: "0"}} className={style.splashLinear}>
        <div className={style.splashCircle}></div>
        <div className={style.splashLinear2}>
         <div style={{width: "70px"}} className={style.splashTexts}></div>
         <div style={{width: "40px"}} className={style.splashTexts}></div>
        </div>
       </div>
{/**/}
     <div className={style.splashSettingBox}>
     
       <div className={style.splashLinear}>
        <div style={{width: "10%"}} className={style.splashTexts}></div>
        <div style={{width: "70%"}} className={style.splashTexts}></div>
       </div>
       
       <div className={style.splashLinear}>
        <div style={{width: "10%"}} className={style.splashTexts}></div>
        <div style={{width: "70%"}} className={style.splashTexts}></div>
       </div>
       
       <div className={style.splashLinear}>
        <div style={{width: "10%"}} className={style.splashTexts}></div>
        <div style={{width: "70%"}} className={style.splashTexts}></div>
       </div>
       
       <div className={style.splashLinear}>
        <div style={{width: "10%"}} className={style.splashTexts}></div>
        <div style={{width: "70%"}} className={style.splashTexts}></div>
       </div>
       
       <div className={style.splashLinear}>
        <div style={{width: "10%"}} className={style.splashTexts}></div>
        <div style={{width: "70%"}} className={style.splashTexts}></div>
       </div>
       
       <div className={style.splashLinear}>
        <div style={{width: "10%"}} className={style.splashTexts}></div>
        <div style={{width: "70%"}} className={style.splashTexts}></div>
       </div>
       
       <div className={style.splashLinear}>
        <div style={{width: "10%"}} className={style.splashTexts}></div>
        <div style={{width: "70%"}} className={style.splashTexts}></div>
       </div>
       
       <div className={style.splashLinear}>
        <div style={{width: "10%"}} className={style.splashTexts}></div>
        <div style={{width: "70%"}} className={style.splashTexts}></div>
       </div>
       
       <div className={style.splashLinear}>
        <div style={{width: "10%"}} className={style.splashTexts}></div>
        <div style={{width: "70%"}} className={style.splashTexts}></div>
       </div>
       
       </div>
{/**/}
      </div>
      
       :
       
      <div className={style.drowerBox}>
       <div id={style.profileDiv}>
        <div style={{backgroundImage: `url(${userData.profilePicture ? userData.profilePicture : "/images/user.png"})`}} id={style.profilePicture}>
          <p>{level(userData?.exp)}</p>
         </div>
        <div>
         <p>{userData?.name}</p>
         <span>{userData?.exp} EXP</span>
        </div>
       </div>
       <div id={style.settingsDiv}>
       <div className={style.hl}></div>
            <div className={style.headings}>
              In App
            </div>
     {/**/}
            <div className={style.settingOptions}>
            <div>
              <i className="fa-solid fa-money-check"></i>
              Withdrow Money
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
     {/**/}
            <div className={style.settingOptions}>
            <div>
              <i className="fa-solid fa-headset"></i>
              Customer Care
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
    {/**/}
            <div className={style.settingOptions} onClick={logOut}>
            <div>
              <i className="fa-solid fa-right-from-bracket"></i>
              LogOut
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
            <div className={style.hl}></div>
    {/**/}
            <div className={style.headings}>
              About App
            </div>
    {/**/}
            <div className={style.settingOptions} data-page="TermsAndConditions" onClick={changePage}>
            <div>
              <i className="fa-solid fa-file-lines"></i>
              Teams And Conditions
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
    {/**/}
            <div className={style.settingOptions} data-page="PrivacyPolicy" onClick={changePage}>
            <div>
              <i className="fa-solid fa-shield-halved"></i>
              Privacy Policy
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
    {/**/}
           <div className={style.settingOptions} data-page="About" onClick={changePage}>
            <div>
              <i className="fa-solid fa-circle-info"></i>
              About Us
            </div>
              <p className="fa-solid fa-chevron-right"></p>
            </div>
    {/**/}
          <p id={style.footer}>Season First | Earn Money </p>
          </div>
      </div> }
      <div onClick={drowerClose} id={style.blank}>
       
      </div>
     </div>
     { loading && <Loading /> }
     { confirm && <Confirm 
      confirm={setConfirm} 
      confirmVal={setConfirmVal}
      title={confirmData.title}
      msg={confirmData.msg}/> }
    </>
    );
};
export default Drower;