// imports 
import style from "./css/Loading.module.css";

function Loading(){
  return(
    <>
     <div id={style.bg}>
       <div id={style.circle}>
         <div id={style.loader}> </div>
       </div>
     </div>
    </>
    )
};
export default Loading;