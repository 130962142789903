//imports
import React, { useState, useEffect } from "react";
import style from "./App.module.css";
import Dashboard from "./Components/Dashboard.js"; //Components
import Splash from "./Components/Splash.js"; //Components
import Signin from "./Components/Signin.js"; //Components
import { auth, db } from "./firebase.js";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { get, ref as refDb } from "firebase/database";
import SkillCrush from "./Components/SkillCrush.js"; //Components
import SkillCrushLobby from "./Components/SkillCrushLobby.js"; //Components
import CardEW from "./Components/CardEW.js";
//Components
import TicTacToeLobby from "./Components/TicTacToeLobby.js"; //Components
import TicTacToe from "./Components/TicTacToe.js" //Components
import TttGameMaking from "./Components/TttGameMaking.js"; //Components
import TermsAndConditions from "./Components/TermsAndConditions.js" //Components
import PrivacyPolicy from "./Components/PrivacyPolicy.js"; //Components
import Rankbadge from "./Components/Rankbadge.js"; //Components
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Confirm from "./Components/Confirm.js"; //components
import Ec from "./Components/Ec.js"; //Components
import Story from "./Components/Story.js"//Components

//

function App() {
  // variables and react hooks
  const [appClose, setAppClose] = useState(null);
  const [curPage, setPage] = useState("Splash");
  const [confirm, setConfirm] = useState(false);
  const [confirmVal, setConfirmVal] = useState(false);
  const [confirmData, setConfirmData] = useState({});
  const [gameItems, setGameItems] =useState({});
  const audio = new Audio("/click.wav");
  const playSound = () => {
    audio.currentTime = 0
    audio.play();
  };
  
  
  
  // functions
  useEffect(() => {
    get(refDb(db, "appClose/")).then((snapshot) => {
      if(snapshot.exists()){
        if(snapshot.val() === true){
          setAppClose(true);
        }else{
          setAppClose(false);
        };
      };
    });
    //
  document.addEventListener("click", () => {
    playSound();
  });
  }, []);
  
  useEffect(() => {
    if(appClose != null){
   onAuthStateChanged(auth, (detectedUser) => {
     if(appClose == false){
     setTimeout(function() {
      if (detectedUser) {
        setPage("Dashboard");
      }else{
        setPage("Signin");
      };
     }, 3000);
   }else{
    setConfirmVal(false);
    setConfirm(true);
    setConfirmData({
      title: "Notice!",
      msg: "Dear Users, We are excited to announce that the E-Zexa App is currently under development. Our team is working diligently to ensure it meets the highest standards of quality, usability, and performance. Once the app is fully developed and tested, it will be officially launched and made available to the public. We appreciate your patience and support during this process and look forward to bringing you an amazing platform soon! Stay tuned for N, and thank you for being a part of our journey. Warm regards, E-Zexa Team.",
    });
   };
   });   
    }
  }, [appClose]);
  
  //
  
  const goToPage = (pageName, gameItems) => {
    setPage(pageName);
    setGameItems(gameItems);
  };


  
  return (
    <>
    {/*screens*/}
     { curPage === "Splash" && <Splash /> }
     { curPage === "Dashboard" && <Dashboard goToPage={goToPage} /> }
     { curPage === "Signin" && <Signin /> }
     { curPage === "SkillCrush" && <SkillCrush goToPage={goToPage} gameItems={gameItems} /> }
     { curPage === "SkillCrushLobby" && <SkillCrushLobby goToPage={goToPage} />}
     { curPage === "CardEW" && <CardEW goToPage={goToPage}/> }
     { curPage === "TermsAndConditions" && <TermsAndConditions goToPage={goToPage} />}
     { curPage === "PrivacyPolicy" && <PrivacyPolicy goToPage={goToPage} />}
     { curPage === "TicTacToeLobby" && <TicTacToeLobby goToPage={goToPage} /> }
     { curPage === "Story" && <Story goToPage={goToPage} storyNumber={gameItems} /> }
     { curPage === "TicTacToe" && <TicTacToe goToPage={goToPage} gameItems={gameItems} /> }
     { curPage === "TttGameMaking" && <TttGameMaking goToPage={goToPage} gameItems={gameItems}/> }
     { curPage === "Rankbadge" && <Rankbadge goToPage={goToPage}/> }
     { curPage === "Ec" && <Ec goToPage={goToPage} /> }
     {/*Confirm*/}
     { confirm && <Confirm 
      confirm={setConfirm} 
      confirmVal={setConfirmVal}
      title={confirmData.title}
      msg={confirmData.msg}/> }
    </>
  );
}

export default App;
