//imports
import React, { useState, useEffect } from "react";
import style from "./css/Story.module.css";

function Story(props){
  //variables and React hooks
  //
  const [loader, setLoader] = useState(0);
  
  //functions
  //
  const goBack = () => {
     props.goToPage("Dashboard");
  };
  
  useEffect(() => {
    const unsubscribe = setInterval(function() {
      setLoader(pre => {
        if(pre >= 100){
          goBack();
          clearInterval(unsubscribe);
          return 100;
        };
        return pre + 0.1 
      });
    }, 20);
    return() => clearInterval(unsubscribe);
  }, []);
  
  
  //return
  return(
    <>
     <div id={style.bg}>
      <div id={style.story}>
       <div id={style.storyHeader}>
        <div id={style.loaderDiv}>
         <div id={style.loaderContainer}>
          <div style={{width: `${loader}%`}} id={style.loader}></div>
         </div>
        </div>
        <div id={style.top}>
         <div className={style.linear}>
           <img src="/images/storybanner1.jpg"/>
           <p>WINNER OF THIS SEASON</p>
         </div>
         <i onClick={goBack} className="fa-solid fa-xmark"></i>
        </div>
       </div>
       <div id={style.arrow}><i className="fa-solid fa-chevron-down"></i></div>
      </div>
      <div id={style.footer}>
       <button>
        Learn more
       </button>
      </div>
     </div>
    </>
    );
};

export default Story;