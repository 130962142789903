// imports 
import React, { useState, useEffect, useRef } from "react";
import style from "./css/SeasonRewards.module.css";
import { auth, db } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { get, set, ref as refDb, onValue } from "firebase/database";
import Toast from "./Toast.js"; //components

function SeasonRewards(){
  //
  // variable 
  //
  const rs = "₹";
  const [userRuppee, setUserRuppee] = useState(0);
  const [readyForClaim, setReadyForClaim] = useState({
    rewardBtn1: false,
    rewardBtn2: false,
    rewardBtn3: false,
  })
  const [rewardOb, setRewardOb] = useState({});
  const [userExp, setUserExp] = useState(0);
  const [user, setUser] = useState(null);
  const rewardBtn1 = useRef(null);
  const rewardBtn2 = useRef(null);
  const rewardBtn3 = useRef(null);
  const [toast, setToast] = useState(false);
  const [toastData, setToastData] =useState({});
  //
  //functions
  //
    const toastShow = () => {
    setToast(!toast);
    setTimeout(function() {
      setToast(false);
    }, 5000);
   };
  //
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (detectedUser) => {
      if(detectedUser){
      setUser(detectedUser);
      }
    });
    return() => {
      unsubscribe()
      };;
  }, []);
  
  //
  
  useEffect(() => {
    if(user){
        const ruppeeListner = onValue(refDb(db, "user/" + user.uid), (snapshot) => {
          if(snapshot.exists()){
            setUserRuppee(snapshot.val().ruppee);
            setUserExp(snapshot.val().stars);
            setRewardOb(snapshot.val().seasonRewards);
             //rewardBtn1
          if(snapshot.val().seasonRewards.silver){
            if(rewardBtn1.current){
            rewardBtn1.current.innerText = "claimed";
            setReadyForClaim(pre => ({
                ...pre,
                rewardBtn1: false,
              }));
            };
          }else{
            if(snapshot.val().stars >= 30){
              if(rewardBtn1.current){
              rewardBtn1.current.style.backgroundColor = "gold";
              setReadyForClaim(pre => ({
                ...pre,
                rewardBtn1: true,
              }));
            };
            };
          };
 //rewardBtn2
        if(snapshot.val().seasonRewards.gold){
            if(rewardBtn2.current){
            rewardBtn2.current.innerText = "claimed";
            setReadyForClaim(pre => ({
                ...pre,
                rewardBtn2: false,
              }));
            }
          }else{
            if(snapshot.val().stars >= 50){
              if(rewardBtn2.current){
              rewardBtn2.current.style.backgroundColor = "gold";
              setReadyForClaim(pre => ({
                ...pre,
                rewardBtn2: true,
              }));
              };  
            };
          };
 //rewardBtn3
         if(snapshot.val().seasonRewards.diamond){
            if(rewardBtn3.current){
            rewardBtn3.current.innerText = "claimed";
            setReadyForClaim(pre => ({
                ...pre,
                rewardBtn3: false,
              }));
            }
          }else{
            if(snapshot.val().stars >= 100){
              if(rewardBtn3.current){
              rewardBtn3.current.style.backgroundColor = "gold";
              setReadyForClaim(pre => ({
                ...pre,
                rewardBtn3: true,
              }));
              };
            };
  };
 //
          };
        });
        
    };
  }, [user])
  
  
  //on rewardBtn1 click
  const rewardBtn1Click = async () => {
    try{
    if(readyForClaim.rewardBtn1){
      setToastData({
        color: "green",
        msg: `claimed ${rs}5`, 
      });
      toastShow();
      rewardBtn1.current.style.backgroundColor = "silver";
      rewardBtn1.current.innerText = "claimed";
      setReadyForClaim(pre => ({
        ...pre,
        rewardBtn1: false,
      }));
      await set(refDb(db, "user/" + user.uid + "/seasonRewards/"), {
        ...rewardOb,
        silver: true,
      });
      await set(refDb(db, "user/" + user.uid + "/ruppee/"), userRuppee + 5);
      setUserRuppee(userRuppee + 5);
    };
    }catch{
      
    }
  };
  //on rewardBtn2 click
  const rewardBtn2Click = async () => {
    try{
    if(readyForClaim.rewardBtn2){
      setToastData({
        color: "green",
        msg: `claimed ${rs}10`, 
      });
      toastShow();
      setReadyForClaim(pre => ({
        ...pre,
        rewardBtn2: false,
      }));
      rewardBtn2.current.style.backgroundColor = "silver";
      rewardBtn2.current.innerText = "claimed";
      await set(refDb(db, "user/" + user.uid + "/seasonRewards/"), {
        ...rewardOb,
        gold: true,
      });
      await set(refDb(db, "user/" + user.uid + "/ruppee/"), userRuppee + 10);
       setUserRuppee(userRuppee + 10);
    };
    }catch{
      
    }
  };
  //on rewardBtn3 click
  const rewardBtn3Click = async () => {
    try{
    if(readyForClaim.rewardBtn3){
      setToastData({
        color: "green",
        msg: `claimed ${rs}15`, 
      });
      toastShow();
      setReadyForClaim(pre => ({
        ...pre,
        rewardBtn3: false,
      }));
      rewardBtn3.current.style.backgroundColor = "silver";
      rewardBtn3.current.innerText = "claimed";
      await set(refDb(db, "user/" + user.uid + "/seasonRewards/"), {
        ...rewardOb,
        diamond: true,
      });
      await set(refDb(db, "user/" + user.uid + "/ruppee/"), userRuppee + 15);
       setUserRuppee(userRuppee + 15);
    };
    }catch{
      
    };
  };
  //
  return(
    <>
     <div id={style.bg}>
       <div id={style.header}>
        <h1>
         Season Rewards
        </h1>
        {/*<p>
        E-Zexa's season 2 Rank up rewards
        </p>*/}
       </div>
       <div id={style.body}>
        <div className={style.linear}>
          <p>Silver</p>
          <div className={style.rewards}>
           {rs}5
          </div>
          <button className={style.btns} ref={rewardBtn1} onClick={rewardBtn1Click}> claim </button>
        </div>
        <div className={style.linear}>
          <p>Gold</p>
          <div className={style.rewards}>
           {rs}10
          </div>
          <button className={style.btns} ref={rewardBtn2} onClick={rewardBtn2Click}> claim </button>
        </div>
        <div className={style.linear}>
          <p>Diamond</p>
          <div className={style.rewards}>
           {rs}15
          </div>
          <button className={style.btns} ref={rewardBtn3} onClick={rewardBtn3Click}> claim </button>
        </div>
       </div>
     </div>
     { toast && <Toast msg={toastData.msg} color={toastData.color} toastShow={toastShow} /> }
    </>
    );
};

export default SeasonRewards;