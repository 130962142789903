  // imports 
  import React, { useState, useEffect } from "react";
  import style from "./css/SkillCrushLobyy.module.css";
  import banner from "../assets/skillcrushbanner.jpg";
  import LobbyBoxes from "./LobbyBoxes.js"; // Components
  import { auth, db } from "../firebase.js";
  import { onAuthStateChanged } from "firebase/auth";
  import { get, set, ref as refDb, onValue } from "firebase/database";
  import Loading from "./Loading.js"; //Components
  import Toast from "./Toast.js"; // Components
  
  function SkillCrushLobby(props) {
    //
    // variables and react hooks
    //
    const rs = "₹";
    const [user, setUser] =useState(null);
    const [userRuppee, setUserRupee] = useState(0);
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);
    const [toastData, setToastData] = useState({mgs: "null", color: "black"});
    //
    // functions
    //
    useEffect(() => {
      const authListener = onAuthStateChanged(auth, async (detectedUser) => {
        if(detectedUser){
          setUser(detectedUser);
          //
        };
      });
      return () => authListener();
    }, []);
    
    //
    useEffect(() => {
      if(user){
        const unsubscribe = onValue(refDb(db, "user/" + user.uid + "/ruppee/"), (snapshot) => {
            if(snapshot.exists()){
              setUserRupee(snapshot.val());
            };
          });
          return () => unsubscribe();
      };
    }, [user]);
    
    //
    const goBack = () => {
      props.goToPage("Dashboard");
    };
    //
   
    return(
      <>
       <div id={style.bg}>
         <div id={style.banner}>
         <div id={style.header}>
          <i className="fa-solid fa-chevron-left" onClick={goBack}></i>
          <div id={style.wallet}>
            {rs}{userRuppee.toFixed(1)}
          </div>
         </div>
         </div>
         <div id={style.linear}>
         <h1>
           Skill Crush Lite
         </h1>
         <LobbyBoxes game="scl" goToPage={props.goToPage} title={"Recomended Game"} fee={1} prize={"Top 25x"} />
         <LobbyBoxes game="scl" goToPage={props.goToPage} title={"Best Game for You"} fee={2} prize={"Top 25x"}  />
         <LobbyBoxes game="scl" goToPage={props.goToPage} title={"Best Game for You"} fee={5} prize={"Top 25x"}  />
         <LobbyBoxes game="scl" goToPage={props.goToPage} title={"Heigh Recomended Game"} fee={10} prize={"Top 50x"}  />
         </div>
       </div>
       { loading && <Loading/> }
      </>
      );
  };
  export default SkillCrushLobby
