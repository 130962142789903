//import
import React, { useEffect, useState, useRef } from "react";
import style from "./css/Notification.module.css";
import { auth, db } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { onValue, ref as refDb } from "firebase/database";

function Notification(props){
  //
  // variables and react hooks
  //
  
   const [user, setUser] = useState(null);
   const [notifications, setNotifications] = useState({});
  //
  // functions
  //
  useEffect(() => {
    const authListener = onAuthStateChanged(auth, (detectedUser) => {
      if(detectedUser){
        setUser(detectedUser);
      };
    }); 
    return authListener;
  }, []);
  useEffect(() => {
    if(user){
      const unsubscribe = onValue(refDb(db, "user/" + user.uid + "/notification/"), (snapshot) => {
        if(snapshot.exists()){
          setNotifications(snapshot.val());
        };
      });
      return unsubscribe;
    };
  }, [user]);
  //
  return(
    <>
     <div id={style.bg}>
       {/*header (start)*/}
        <div id={style.header}>
         <p><i className="fa-solid fa-chevron-left"></i>Notifications</p>
        </div>
       {/*header (end)*/}
       {/*body (start)*/}
        <div id={style.body}>
          { notifications && Object.keys(notifications).map((key) => (
            <div key={key} className={style.notificationDiv}>
             <img src={notifications[key].img} className={style.notificationImg} />
             <p>{notifications[key].msg} <span>{notifications[key].date}</span></p>
            </div>
          ))}
        </div>
       {/*body (start)*/}
     </div>
    </>
    );
};
export default Notification;