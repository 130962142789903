//imports
import React, { useState } from "react";
import style from "./css/Signin.module.css";
import { auth, provider, onAuthStateCahnged, signInWithPopup, db } from "../firebase.js";
import { get, push, onValue, set, ref as refDb, update } from "firebase/database";
import Loading from "./Loading.js";
import Toast from "./Toast.js"; // componets
import { useNavigate } from "react-router-dom";

function Signin(props){
  // variables and react hooks
  const rs = "₹";
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isAgree, setAgree] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastData, setToastData] = useState({msg: "this is toast", color: "red"});
  const now = new Date();
  const date = now.toLocaleDateString();
  const time = now.toLocaleTimeString('en-US');
  const dateAndTime = `${date} ${time}`;
  //
  
  // functions
  const showToast = () => {
    setToast(!toast);
    setTimeout(function() {
      setToast(false);
    }, 5000);
  };
  //
  const goToTAC = () => {
    navigate("/TermsAndConditions");
  };
  //
  const onCheckboxChanged = (e) => {
    setAgree(e.target.checked);
  };
  const signinClicked = async () => {
    if(isAgree){
    setLoading(true);
    try{
    const snapshot = await signInWithPopup(auth, provider);
    const userRef = refDb(db, "user/" + snapshot.user.uid);
    const userData = await get(userRef);
    if(!userData.exists()){
      await update(userRef, {
      ruppee: 10,
      ec: 0,
      exp: 100,
      stars: 0,
      name: snapshot.user.uid.substring(0,4),
      profilePicture: "/images/user.png",
      levelUpRewards: {
        level5: false,
        level10: false,
        level20: false,
      },
      seasonRewards: {
        silver: false,
        gold: false,
        diamond: false,
      },
      dailyMissons: {
        task1: {
          track: 0,
          date: false,
          claimed: false,
        },
        task2: {
          track: 0,
          date: false,
          claimed: false,
        },
        task3: false,
      },
    });
    push(refDb(db, "user/" + snapshot.user.uid + "/notification"), {
      msg: "Dear new E-Zexa user, you have successfully  created an account in E-Zexa and you have also earned ₹10 for SignUp bonus! ",
      img: "./images/icon.png",
      date: dateAndTime,
    });
    };
    setToastData({
        msg: "Login successfull!",
        color: "green",
      })
      showToast();
    }catch(e){
      setToastData({
        msg: e.message,
        color: "red",
      })
      showToast();
    }finally{
      setLoading(false);
    }
    }else{
      setToastData({
        msg: "please agree App's Terms & Conditions!",
        color: 'red',
      });
      showToast();
    };
  };
  
  return (
    <>
     <div id={style.bg} >
     <div id={style.heading} >
     <img src="/images/icon.png"/>
       <p >Welcome to the App!</p>
       </div>
       <img src="/images/login.gif" id={style.thambnail} />
       <div id={style.contents} >
         <button id={style.btn} onClick={signinClicked}>
           <img src="/images/googleicon.png" style={{width: '30px', marginRight: '6px'}} />
           Singin With Google
         </button>
         <div id={style.checkbox}>
         <input type="checkbox" style={{marginRight: "8px"}} onChange={onCheckboxChanged}/>
         Agree App's <span onClick={goToTAC}>terms</span> & <span onClick={goToTAC}>conditions</span>
         </div>
         <p>Welcome to E-Zexa App! To enter in the App You need to Signin with your google account. Click on Singin button given below and then you will be enter into the App.
         </p>
       </div>
     </div>
     { loading && <Loading /> }
     { toast && <Toast msg={toastData.msg} toastShow={showToast} color={toastData.color}/> }
     </>
    );
};
export default Signin;