// imports 
import React, { useState, useEffect, useRef } from "react";
import style from "./css/LevelUpRewards.module.css";
import { auth, db } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { get, set, ref as refDb } from "firebase/database";

function LevelUpRewards(){
  //
  // variable 
  //
  const rs = "₹";
  const [userRuppee, setUserRuppee] = useState(0);
  const [readyForClaim, setReadyForClaim] = useState({
    rewardBtn1: false,
    rewardBtn2: false,
    rewardBtn3: false,
  })
  const [rewardOb, setRewardOb] = useState({});
  const [userExp, setUserExp] = useState(0);
  const [user, setUser] = useState(null);
  const rewardBtn1 = useRef(null);
  const rewardBtn2 = useRef(null);
  const rewardBtn3 = useRef(null);
  //
  //functions
  //
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (detectedUser) => {
      if(detectedUser){
        try{
        setUser(detectedUser);
        
        const ruppeeSnapshot = await get(refDb(db, "user/" + detectedUser.uid + "/ruppee/"));
        setUserRuppee(ruppeeSnapshot.val());
        
        const expSnapshot = await get(refDb(db, "user/" + detectedUser.uid + "/exp/"));
        setUserExp(expSnapshot.val());
        
        get(refDb(db, "user/" + detectedUser.uid + "/levelUpRewards/")).then((snapshot) => {
          setRewardOb(snapshot.val());
  //rewardBtn1
  if(snapshot.exists()){
          if(snapshot.val().level5){
            if(rewardBtn1.current){
            rewardBtn1.current.innerText = "claimed";
            }
          }else{
            if(expSnapshot.val() > 4000){
              if(rewardBtn1.current){
              rewardBtn1.current.style.backgroundColor = "gold";
              setReadyForClaim(pre => ({
                ...pre,
                rewardBtn1: true,
              }));
            };
            };
          };
 //rewardBtn2
        if(snapshot.val().level10){
            if(rewardBtn2.current){
            rewardBtn2.current.innerText = "claimed";
            }
          }else{
            if(expSnapshot.val() > 9000){
              if(rewardBtn2.current){
              rewardBtn2.current.style.backgroundColor = "gold";
              setReadyForClaim(pre => ({
                ...pre,
                rewardBtn2: true,
              }));
              };  
            };
          };
 //rewardBtn3
         if(snapshot.val().level20){
            if(rewardBtn3.current){
            rewardBtn3.current.innerText = "claimed";
            }
          }else{
            if(expSnapshot.val() > 19000){
              if(rewardBtn3.current){
              rewardBtn3.current.style.backgroundColor = "gold";
              setReadyForClaim(pre => ({
                ...pre,
                rewardBtn3: true,
              }));
              };
            };
          };
  };
 //
        });
      }catch{};
      };
    });
    return() => {
      unsubscribe()
      };;
  }, []);
  
  
  //on rewardBtn1 click
  const rewardBtn1Click = async () => {
    try{
    if(readyForClaim.rewardBtn1){
      rewardBtn1.current.style.backgroundColor = "silver";
      rewardBtn1.current.innerText = "claimed";
      await set(refDb(db, "user/" + user.uid + "/levelUpRewards/"), {
        ...rewardOb,
        level5: true,
      });
      await set(refDb(db, "user/" + user.uid + "/ruppee/"), userRuppee + 10);
      setUserRuppee(userRuppee + 10);
    };
    }catch{
      
    }
  };
  //on rewardBtn2 click
  const rewardBtn2Click = async () => {
    try{
    if(readyForClaim.rewardBtn2){
      rewardBtn2.current.style.backgroundColor = "silver";
      rewardBtn2.current.innerText = "claimed";
      await set(refDb(db, "user/" + user.uid + "/levelUpRewards/"), {
        ...rewardOb,
        level10: true,
      });
      await set(refDb(db, "user/" + user.uid + "/ruppee/"), userRuppee + 15);
       setUserRuppee(userRuppee + 15);
    };
    }catch{
      
    }
  };
  //on rewardBtn3 click
  const rewardBtn3Click = async () => {
    try{
    if(readyForClaim.rewardBtn3){
      rewardBtn3.current.style.backgroundColor = "silver";
      rewardBtn3.current.innerText = "claimed";
      await set(refDb(db, "user/" + user.uid + "/levelUpRewards/"), {
        ...rewardOb,
        level20: true,
      });
      await set(refDb(db, "user/" + user.uid + "/ruppee/"), userRuppee + 20);
       setUserRuppee(userRuppee + 20);
    };
    }catch{
      
    };
  };
  //
  return(
    <>
     <div id={style.bg}>
       <div className={style.linear}>
         <img src="/images/createBox.png"/>
         <p>Level 5</p>
         <div className={style.space}></div>
         <span>{rs}10</span>
         <button ref={rewardBtn1} onClick={rewardBtn1Click}> claim </button>
       </div>
       <div className={style.linear}>
         <img src="/images/createBox.png"/>
          <p>Level 10</p>
          <div className={style.space}></div>
          <span>{rs}15</span>
         <button ref={rewardBtn2} onClick={rewardBtn2Click}> claim </button>
       </div>
       <div className={style.linear}>
         <img src="/images/createBox.png"/>
          <p>Level 20</p>
          <div className={style.space}></div>
          <span>{rs}20</span>
         <button ref={rewardBtn3} onClick={rewardBtn3Click}> claim </button>
       </div>
     </div>
    </>
    );
};

export default LevelUpRewards;