// imports 
import React, { useState, useEffect, useRef } from "react";
import style from "./css/CardEW.module.css";
import { auth, db } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { onValue, set, get, ref as refDb } from "firebase/database";
import Loading from "./Loading.js";// components
import Toast from "./Toast.js"; // components

function CardEW(props){
  //
  //variables and React Hooks
  //
  const rs = "₹";
  const newDate = new Date();
  const date = `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getYear()}`;
  const [play, setPlay] = useState(false);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [userRuppee, setUserRuppee] = useState(0);
  const [gameMoney, setGameMoney] = useState(1);
  const [loading, setLoading] = useState(true);
  const [cardEagleSrc, setCardEagleSrc] = useState("./images/card.png");
  const [cardWolfSrc, setCardWolfSrc] = useState("./images/card.png");
  const wolfRef = useRef(null);
  const tieRef = useRef(null);
  const eagleRef = useRef(null);
  const [selectTeam,setSelectTeam] = useState(0)
  const [playBtn, setPlayBtn] = useState("PLAY");
  let winnigTeam = 0;
  const [matchFinish, setMatchFinish] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastData, setToastData] =useState({});
  
  //
  //functions
  //Toast
  //
  const toastShow = () => {
    setToast(!toast);
    setTimeout(function() {
      setToast(false);
    }, 5000);
  };
  //
  //
  //
  useEffect(() => {
    const authListener = onAuthStateChanged(auth, (detectedUser) => {
      if(detectedUser){
        setUser(detectedUser);
        setTimeout(function() {
          setLoading(false);
        }, 2000);
      };
    });
    return() => authListener();
  }, []);
  //
  //
  useEffect(() => {
    if(user){
      const userRuppeeListener = onValue(refDb(db, "user/" + user.uid + "/ruppee/"), (snapshot) => {
      setUserRuppee(snapshot.val() || 0);
      });
       return() => userRuppeeListener();
    };
  }, [user]);
  //
  const goBack = () => {
    if(!play){
    props.goToPage("Dashboard");
    }
  }
  //
  //
  const increseFun = () => {
    if(!play){
    if(gameMoney < 10){
      setGameMoney(gameMoney+1);
    };
    };
  };
  //
  const decreseFun = () => {
    if(!play){
    if(gameMoney > 1){
      setGameMoney(gameMoney-1)
    };
    };
  };
  //
  //
  const select = (e) => {
  if (!play) {
    wolfRef.current.style.border = "2px solid white";
    tieRef.current.style.border = "2px solid white";
    eagleRef.current.style.border = "2px solid white";
    
    e.currentTarget.style.border = "2px solid yellow";
    setSelectTeam(Number(e.currentTarget.getAttribute("select")));
    //
  }
};
  //
  //
  const playClick = async () => {
    if(userRuppee >= gameMoney){
    if(!play && selectTeam != 0){
    let cardEagle, cardWolf;
    setLoading(true);
    await set(refDb(db, "user/" + user.uid+ "/ruppee/"), userRuppee - gameMoney);
    setLoading(false);
    setPlay(true);
    setPlayBtn(10);
    const timmer = setInterval(function() {
      setPlayBtn((pre) => {
        if(pre <= 1){
         clearInterval(timmer);
        };
        return pre - 1
        });
    }, 1000);
    setTimeout(function() {
      if(userRuppee >= 100){
        if(selectTeam == 1){
         cardEagle = Math.floor(Math.random() * 2 +1);
         cardWolf = Math.floor(Math.random() * 6 +1);
        }else if(selectTeam == 3){
         cardEagle = Math.floor(Math.random() * 6 +1);
         cardWolf = Math.floor(Math.random() * 2 +1);
        };
      }else{
       cardEagle = Math.floor(Math.random() * 6 +1);
       cardWolf = Math.floor(Math.random() * 6 +1);
      };
          //
          //
          if(cardWolf == cardEagle){
          tieRef.current.style.backgroundColor = "green";
          winnigTeam = 2;
        }else if(cardWolf < cardEagle){
          eagleRef.current.style.backgroundColor = "green";
          winnigTeam = 1;
        }else if(cardEagle < cardWolf){
          wolfRef.current.style.backgroundColor = "green";
          winnigTeam = 3;
          };
          //
          //
          if(winnigTeam == selectTeam){
            const ruppeeRef = refDb(db, "user/" + user.uid+ "/ruppee/");
            get(ruppeeRef).then((snapshot) => {
              set(ruppeeRef, snapshot.val() + (gameMoney * 2));
            });
            //increse user EXP 
             get(refDb(db, "user/" + user.uid+ "/exp/")).then((snapshot) => {
               set(refDb(db, "user/" + user.uid+ "/exp/"), snapshot.val() + 70);
             });
            //reward add track
            get(refDb(db, "user/" + user.uid + "/dailyMissons/task2/")).then((snapshot) => {
              if(snapshot.val().claimed != date){
              set(refDb(db, "user/" + user.uid+ "/dailyMissons/task2/"), {
               ...snapshot.val(),
               date: date,
               track: snapshot.val().date != date ? 1 : snapshot.val().track + 1,
             });
           };
         });
        };
           setCardEagleSrc(
           cardEagle == 1 ? "./images/card2.png" :
           cardEagle == 2 ? "./images/card3.png" :
           cardEagle == 3 ? "./images/card4.png" :
           cardEagle == 4 ? "./images/cardking.png" :
           cardEagle == 5 ? "./images/cardjoker.png" : 
           "./images/carda.png"
                   );
           //
            setCardWolfSrc(
           cardWolf == 1 ? "./images/card2.png" :
           cardWolf == 2 ? "./images/card3.png" :
           cardWolf == 3 ? "./images/card4.png" :
           cardWolf == 4 ? "./images/cardking.png" :
           cardWolf == 5 ? "./images/cardjoker.png" : 
           "./images/carda.png"
                   );
          //
          //
         setTimeout(function() {
         setLoading(true);
         setTimeout(function() {
         setPlay(false);
         setCardWolfSrc("./images/card.png"   );
         setCardEagleSrc("./images/card.png"   );
         wolfRef.current.style.border = "2px    solid white";
         tieRef.current.style.border = "2px solid white";
         eagleRef.current.style.border = "2px solid white";
         eagleRef.current.style   .backgroundColor = "transparent";
         tieRef.current.style   .backgroundColor = "transparent";
         wolfRef.current.style   .backgroundColor = "transparent";
         setSelectTeam(0);
         setPlayBtn("PLAY");
         setLoading(false);
          }, 1000);
       }, 1500);
    }, 10000);
    }else{
      setToastData({
        color: "red",
        msg: "Please Select a Team!",
      });
      toastShow();
    };
    }else{
      setToastData({
        color: "red",
        msg: "You Have not Money!",
      });
      toastShow();
    };
  };
  //
  //
  return(
    <>
     <div id={style.bg}>
      {/*header (start)*/}
      <div id={style.header}>
        <div id={style.headerLinear1}>
           <i className="fa-solid fa-chevron-left" onClick={goBack}></i>
         <div id={style.wallet} >
            {`${rs} ${userRuppee.toFixed(1)}`}
         </div>
        </div>
        <div id={style.headerLinear2}></div>
      </div>
      {/*header (end)*/}
      {/*body (start)*/}
      <div id={style.body}>
        <div ref={eagleRef}
             className={style.blocks}
             onClick={select}
             select="1">
          <p>2&times;</p>
          <div id={style.egaleDiv} className={style.cardsDiv}>
            <img src={cardEagleSrc} className={style.cards} />
            <p>EAGLE</p>
          </div>
        </div>
        <div ref={tieRef}
             className={style.blocks}
             onClick={select}
             select="2">
         <p>Tie 3&times;</p>
        </div>
        <div ref={wolfRef}
             className={style.blocks}
             onClick={select}
             select="3">
          <div id={style.wolfDiv} className={style.cardsDiv}>
             <img src={cardWolfSrc} className={style.cards} />
             <p>WOLF</p>
          </div>
          <p>2&times;</p>
        </div>
      </div>
      {/*body (end)*/}
      {/*footer (start)*/}
      <div id={style.footer}>
        <div id={style.footerLinear2}>
        </div>
        <div id={style.footerLinear1}>
          <div id={style.moneyControlBtns}>
           <button id={style.increseBtn}
           onClick={increseFun}>
            +
           </button>
           <div id={style.moneyDisplay}>
            {rs}{gameMoney}
           </div>
           <button id={style.decreseBtn} onClick={decreseFun}>
            -
           </button>
         </div>
         <button id={style.playBtn}
                 onClick={playClick}>
           { playBtn }
         </button>
        </div>
      </div>
      {/*footer (end)*/}
     </div>
     { toast && <Toast msg={toastData.msg} color={toastData.color} toastShow={toastShow} /> }
     { loading && <Loading /> }
    </>
    );
};
export default CardEW;