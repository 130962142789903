// imports
import React from "react";
import style from "./css/Details.module.css";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer.js"; //components

function TermsAndConditions() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className={style.taccontainer}>
      <div className={style.header}>
        <i className="fa-solid fa-chevron-left" onClick={goBack}></i>
        <p>Terms & Conditions</p>
      </div>
      <div className={style.taccontent}>
        <p><strong>1. Acceptance of Terms</strong><br />
          By using the E-Zexa app, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you must not access or use this app.
        </p>
        <p><strong>2. Eligibility</strong><br />
          E-Zexa is designed for individuals who are 18 years of age or older. By using the app, you confirm that you meet this requirement.
        </p>
        <p><strong>3. User Account and Security</strong><br />
          Users are required to link an email account to access the app. It is the user's responsibility to ensure the security of their account credentials. E-Zexa is committed to protecting user data and ensuring account security.
        </p>

        <p><strong>4. Earnings and Withdrawals</strong><br />
          - Users can earn money by completing tasks and playing games within the app.<br />
          - Earnings are credited in Indian Rupees (INR).<br />
          - To withdraw earnings, users must provide a valid UPI ID. E-Zexa ensures that withdrawals are processed and credited to the user's account within 24 hours.<br />
          - E-Zexa is not responsible for any delays or issues caused by external UPI service providers.
        </p>

        <p><strong>5. No Investment Required</strong><br />
          E-Zexa allows users to earn money without any investment. Users are not required to make any purchases or deposits to access the earning features of the app.
        </p>

        <p><strong>6. User Responsibilities</strong><br />
          - Users must use the app for lawful purposes only and must not engage in any activity that could harm the app or its users.<br />
          - Users must not attempt to hack, manipulate, or misuse the app in any way.
        </p>

        <p><strong>7. Privacy Policy</strong><br />
          E-Zexa respects user privacy and ensures that user data is protected. By using the app, users agree to the collection and use of their data as outlined in our Privacy Policy.
        </p>

        <p><strong>8. Limitation of Liability</strong><br />
          E-Zexa is not liable for any loss of earnings, technical failures, or data breaches that occur due to factors outside our control.
        </p>

        <p><strong>9. Changes to Terms</strong><br />
          E-Zexa reserves the right to modify these terms and conditions at any time. Users will be notified of any significant changes.
        </p>

        <p><strong>10. Governing Law</strong><br />
          These terms and conditions are governed by the laws of India. Any disputes arising from the use of the app will be subject to the exclusive jurisdiction of the courts of Mirzapur, Uttar Pradesh (India).
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default TermsAndConditions;