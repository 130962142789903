//imports  
import React, {useState, useEffect} from "react";
import style from "./css/Ec.module.css";
import { BarChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, Bar } from 'recharts';
import Footer from "./Footer.js"//components
import { auth, db } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { onValue, get, set, ref as refDb, update } from "firebase/database";
import Toast from "./Toast.js"; //components
import Loading from "./Loading.js"; //components


function Ec(props){
  //
  // variables and hooks 
  //
  const rs = "₹";
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({ruppee: 0});
  const [rateData, setRateData] = useState("0 (0%)")
  const [toast, setToast] = useState(false);
  const [toastData, setToastData] =useState({});
  const [data, setData] = useState([{name: '7d', price: 86.6}, {name: '6d', price: 24.2}, {name: '5d', price: 67.9}, {name: '4d', price: 10.4}, {name: '3d', price: 27.6}, {name: '2d', price: 93.0}, {name: 'today', price: 33.1}]);
  const [loading, setLoading] = useState(false);
  
  //
  //functions
  //
  
  const goBack = () => {
     props.goToPage("Dashboard");
  };
  
    //
  const toastShow = () => {
    setToast(!toast);
  };
  //
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (detectedUSer) => {
      if(detectedUSer){
        setUser(detectedUSer);
      };
    });
    return() => unsubscribe();
  }, []);
  
  useEffect(() => {
    if(user){
      const unsubscribe = onValue(refDb(db, "user/" + user.uid), (snapshot) => {
        if(snapshot.exists()){
          setUserData(snapshot.val());
        };
      });
      
      const unsubscribe2 = onValue(refDb(db, "ecPrices/"), (snapshot) => {
        if(snapshot.exists()){
          const prices = Object.entries(snapshot.val());
          const dayName = (index) => {
            const name = ["7d", "6d", "5d", "4d", "3d", "2d", "today"];
            return name[index];
          }
          const lists = prices.slice(-7);
          setData(lists.map((element, index) => {
            return {
              ...element[1],
              day: dayName(index),
            };
          }));
        };
      });
    };
  }, [user]);
  
  const buy = async () => {
    if(userData?.ruppee >= data[6].price){
      setLoading(true);
      await update(refDb(db, "user/" + user?.uid), {
        ec: userData?.ec + 1,
      });
      await update(refDb(db, "user/" + user?.uid), {
        ruppee: userData?.ruppee - data[6]?.price,
      });
      setLoading(false);
      setToastData({
      color: "green",
      msg: "purchased successful!",
      });
      setToast(true);
    }else{
      setToastData({
      color: "red",
      msg: "You need more money to buy a EC  !",
      });
      setToast(true);
    };
  };
  
  const sell = async () => {
    if(userData?.ec > 0){
      setLoading(true);
      await update(refDb(db, "user/" + user?.uid), {
        ec: userData?.ec - 1,
      });
      await update(refDb(db, "user/" + user?.uid), {
        ruppee: userData?.ruppee + data[6]?.price,
      });
      setLoading(false);
      setToastData({
      color: "green",
      msg: "Sold successful!",
      });
      setToast(true);
    }else{
      setToastData({
      color: "red",
      msg: "You don't have EC!",
      });
      setToast(true);
    };
  };
  
  useEffect(() => {
    setRateData(`${data[6].price - data[5].price} (${(data[6].price / data[5].price * 100).toFixed(1)}%)`)
  }, [data]);
  
  return(
    <>
     <div id={style.bg}>
      <div id={style.bg2}>
      <div id={style.header}>
        <div id={style.header}>
        <div id={style.headerBtns}>
          <i onClick={goBack} className="fa-solid fa-chevron-left" ></i>
          <p id={style.logo}>E-Zexa Cash</p>
        </div>
         <div id={style.headerLinear}>
         <div className={style.wallet}>
          <img src="./images/ec.png"/>
          {userData.ec ? userData.ec : 0}
         </div>
         <div className={style.wallet}>
          {rs}{userData?.ruppee.toFixed(1)}
         </div>
         </div>
        </div>
      </div>
      <div className={style.hl}></div>
      
      
      
      <div id={style.priceViewDiv}>
       <div>
        <h1>{rs}{data[6]?.price}</h1>
        <span>E-Zexa Cash price</span>
       </div>
       <p style={{color: (data[6].price - data[5].price) > 0 ? "green" : "red"}} >{rs}{rateData} <i class={(data[6].price - data[5].price) > 0 ? "fa-solid fa-up-long" : "fa-solid fa-down-long"}></i></p>
      </div>
      
      
      <div id={style.notifier}>
       <div id={style.scroll}>
        <div className={style.notifierBoxes}>
        T | {rs}{data[6].price}
        </div>
        <div className={style.notifierBoxes}>
         2d | {rs}{data[5].price}
        </div>
        <div className={style.notifierBoxes}>
        3d | {rs}{data[4].price}
        </div>
        <div className={style.notifierBoxes}>
        4d | {rs}{data[3].price}
        </div>
        <div className={style.notifierBoxes}>
        5d | {rs}{data[2].price}
        </div>
        <div className={style.notifierBoxes}>
        6d | {rs}{data[1].price}
        </div>
        <div className={style.notifierBoxes}>
        7d | {rs}{data[0].price}
        </div>
       </div>
      </div>
      <div className={style.hl}></div>
      
      <div id={style.display}>
       <ResponsiveContainer>
       <BarChart width={300} height={150} data={data}>
    <XAxis dataKey="day" stroke="#e2009f" />
    <YAxis />
     <Tooltip wrapperStyle={{ width: 100, backgroundColor: '#ccc' }} />
     <Legend width={100} wrapperStyle={{ top: 40, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} />
     <CartesianGrid stroke="#ccc" strokeDasharray="2 2" />
     <Bar dataKey="price" fill="#e2009f4d" barSize={30} />
     </BarChart>
      </ResponsiveContainer>
    </div>
    <div className={style.hl}></div>
    
    <div id={style.btns}>
     <div onClick={sell} id={style.sellBtn}>
      SELL
     </div>
     <div onClick={buy} id={style.buyBtn}>
      BUY
     </div>
    </div>
    
    <div id={style.buyAndSellDiv}>
     <div id={style.BASHeader}>
      <div>
       <i className="fa-solid fa-circle"></i>
       Buy & Sell
      </div>
     </div>
     <div id={style.BASBody}>
     <img src="./images/ec.png"/>
     <div>
     <p>      <span>E-Zexa Cash</span>: A Smart Way to Earn and Trade</p> <br/>
        <p>
          <span>E-Zexa Cash</span> is currently priced at just <span style={{color: "green"}}>{rs}{data[6].price}</span>, offering a convenient way for users to buy and sell at any time. This feature provides a smarter option to maximize your earnings with the E-Zexa app. By observing the trends, you can decide the best time to <span>buy</span> or <span>sell</span> and make profitable decisions.
         </p> <br/>
         <p>
           The price of <span>E-Zexa Cash</span> is directly linked to the performance of the E-Zexa app. The app determines when the price will rise or fall, giving users the opportunity to trade accordingly and earn profits.
         </p> <br/>
         <p>
            Disclaimer:
            The price of <span>E-Zexa Cash</span> is subject to fluctuations based on the performance and algorithm of the <span>E-Zexa App</span>. Users are advised to make informed decisions while trading and understand the associated risks. The app holds the right to modify the pricing structure at any time.
          </p>
      </div>
     </div>
     <Footer/>
    </div>
    </div>
     </div>
     { toast && <Toast msg={toastData.msg} color={toastData.color} toastShow={toastShow} /> }
    { loading && <Loading /> }
    </>
    );
};

export default Ec;