//imports
import React, { useState, useEffect } from "react";
import style from "./css/TicTacToe.module.css";
import { auth, db } from "../firebase.js";
import { onAuthStateChanged }  from "firebase/auth";
import { ref as refDb, set, get, onValue, remove, push, onDisconnect, update } from "firebase/database";
import Loading from "./Loading.js";

function TicTacToe(props){
  
  // Variables and React Hooks
  const [status, setStatus] = useState("match");
  const [timmer, setTimmer] = useState(30);
  const [userStars, setUserStars] = useState(0);
  const [curPlayer, setCurPalyer] = useState("");
  const [opponent, setOpponent] = useState({name: "loading..."});
  const [userData, setUserData] = useState({});
  const [roomKey, setRoomKey] = useState(props.gameItems);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [userRuppee, setUserRuppee] = useState(0);
  const [boxVisiblilty, setBoxVisibility] = useState({0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, });
  const [room, setRoom] = useState({});
  const roomRef = refDb(db, "room/" + roomKey);
  const [board, setBoard] = useState({0: "", 1: "" , 2: "", 3: "", 4: "", 5: "", 6: "", 7: "", 8: ""});
  const [alertMsg, setAlertMsg] = useState({show: false, title: "title", msg: 'msg', color: "gold", sign: "fa-solid fa-trophy"});
  const [startingSplash, setStartingSplash] = useState({show: true, text: "Loading..."});
  const [menu, setMenu] = useState(false);
  let timmerInt;
  
  //functions
  
  //timmer function If curPlayerChanges

  useEffect(() => {
    if(curPlayer != ""){
      setTimmer(30);
       timmerInt = setInterval(function() {
          setTimmer(preval => {
            if(preval <= 0){
              if(curPlayer == user.uid){
               setAlertMsg({
               show: true,
               title: "You Lost!",
               msg: "you have lost this match!",
               color: "red",
               sign: "fa-solid fa-circle-xmark",
              });
              }else{
              setAlertMsg({
               show: true,
               title: "You Won!",
               msg: "you have won this match!",
               color: "gold",
               sign: "fa-solid fa-trophy",
               });
               update(roomRef, {
                winner: user.uid, 
              });
               set(refDb(db, "user/" + user.uid + "/ruppee/"), (room.ammount * 2) + userRuppee);
               update(refDb(db, "user/" + user.uid), {stars: userStars + 1})
              };
              clearInterval(timmerInt);
              return 0
            };
            if(status != 'match'){
             clearInterval(timmerInt);
             return 0
            };
             return preval - 1
            });
      }, 1000);
    };
    return () => {
      clearInterval(timmerInt);
    }
  }, [curPlayer, status]);
  
  useEffect(() => {
    if(user){
      const unsubscribe = onValue(refDb(db, "user/" + user.uid), (snapshot) => {
        if(snapshot.exists()){
          setUserData(snapshot.val());
        };
      });
      
      //cheking winner
      const unsubscribe2 = onValue(roomRef, (snapshot) => {
        if(snapshot.exists()){
          if(snapshot.val().winner !== "none" && snapshot.val().winner !== user.uid){
            setAlertMsg({
               show: true,
               title: "You Lost!",
               msg: "you have lost this match!",
               color: "red",
               sign: "fa-solid fa-circle-xmark",
            });
            setStatus("finish");
          };
        };
      });
      
      //
      return () => {
        unsubscribe();
        unsubscribe2();
      };
    };
  }, [user]);
  
  
  // user Listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (detectedUser) => {
      if(detectedUser){
        setUser(detectedUser);
        //user Reppee Listener
        get(refDb(db, "user/" + detectedUser.uid + "/ruppee/")).then((snapshot) => {
           setUserRuppee(snapshot.val());
        });
        get(refDb(db, "user/" + detectedUser.uid + "/stars/")).then((snapshot) => {
          if(snapshot.exists()){
          setUserStars(snapshot.val());
          };
        });
      };
    });
    return() => unsubscribe();
  }, []);
  //
  
  useEffect(() => {
    if(startingSplash && startingSplash.show){
      setTimeout(function() {
        setStartingSplash({show: false});
      }, 3000);
    };
  }, [startingSplash]);
  
  useEffect(() => {
    if(status && status != "match"){
    if(status == 'finish'){
      setTimeout(() => {
        get(roomRef).then((snapshot) => {
          if(snapshot.exists()){
            //
            const room = snapshot.val();
            //
            if(room.curPlayer == user.uid){
              clearInterval(timmerInt);
              setAlertMsg({
               show: true,
               title: "You Lost!",
               msg: "you have lost this match!",
               color: "red",
               sign: "fa-solid fa-circle-xmark",
              });
           }else{
              clearInterval(timmerInt);
               setAlertMsg({
               show: true,
               title: "You Won!",
               msg: "you have won this match!",
               color: "gold",
               sign: "fa-solid fa-trophy",
              });
              update(roomRef, {
                winner: user.uid, 
              });
              set(refDb(db, "user/" + user.uid + "/ruppee/"), (room.ammount * 2) + userRuppee);
              update(refDb(db, "user/" + user.uid), {stars: userStars + 1});
          };
       };
      });
     }, 1000);
    }else if(status == "drow"){
      setTimeout(() => {
        get(roomRef).then((snapshot) => {
          if(snapshot.exists){
            //
            const room = snapshot.val();
            //
            clearInterval(timmerInt);
              setAlertMsg({
               show: true,
               title: "Match drow!",
               msg: "this match is drow!",
               color: "darkcyan",
               sign: "fa-solid fa-gamepad",
              });
              set(refDb(db, "user/" + user.uid + "/ruppee/"), room.ammount + userRuppee);
          };
        });
     }, 1000);
    };
    };
  }, [status]);
  
  useEffect(() => {
    if(board){
    if(board[0] !== "" && board[0] === board[1] && board[1] === board[2] ||
    board[3] !== "" && board[3] === board[4] && board[4] === board[5] ||
    board[6] !== "" && board[6] === board[7] && board[7] === board[8] ||
    board[0] !== "" && board[0] === board[3] && board[3] === board[6] ||
    board[1] !== "" && board[1] === board[4] && board[4] === board[7] ||
    board[2] !== "" && board[2] === board[5] && board[5] === board[8] ||
    board[0] !== "" && board[0] === board[4] && board[4] === board[8] ||
    board[2] !== "" && board[2] === board[4] && board[4] === board[6] )
    {
      setStatus("finish");
    }else  if(
        board[0] !== "" && board[1] !== 
        "" && board[2] !== "" && board[3]
        !== "" && board[4] !== "" &&
        board[5] !== "" && board[6] !== ""
        && board[7] !== "" && board[8] !==
        ""
    ){
      setStatus("drow");
    }
  };
  }, [board]);
  
  //get In Room and get its Data 
  useEffect(() => {
    if(roomKey && user && status != "finish"){
      //OnValue Changed 
      const unsubscribe = onValue(roomRef, (snapshot) => {
        if(snapshot.exists()){
          const room = snapshot.val();
            ///
            setStartingSplash(pre => ({
              ...pre,
              text: room.curPlayer == user.uid ? "You have got the first chance to play the game." : "Your opponent have got the first chance to play the game"
            }))
            ///
          setRoom(room);
          setOpponent(room.player2 === user.uid ? room.player1Data : room.player2Data);
          for (let i = 0; i < room.board.length; i++) {
            if(room.board[i] != ""){
              if(room.board != "" && room.board[i] != user.uid){
              setBoard(pre => ({
                ...pre,
                [i]: "/images/o.png",
              }));
              setBoxVisibility(pre => ({
                ...pre,
                [i]: true,
               }));
              }else{
              setBoard(pre => ({
                ...pre,
                [i]: "/images/x.png",
              }));
              setBoxVisibility(pre => ({
                ...pre,
                [i]: true,
               }));
              };
            };
          };
        };
      });
      
      //on Cur player changes 
      const unsubscribe2 = onValue(refDb(db, `room/${roomKey}/curPlayer`), (snapshot) => {
        if(snapshot.exists()){
          setCurPalyer(snapshot.val());
        };
      });
      
      //return 
      return () => {
        unsubscribe();
        unsubscribe2();
      };
    };
  }, [roomKey, user, status]);
  
  
  
  //on alert MEssage Ok button click
  const alertMsgBtnClicked = () => {
    //set loading
    setLoading(true);
    setAlertMsg({show: false});
    //get database and get room ref
    get(roomRef).then((snapshot) => {
      //if snapshot data exists
      if(snapshot.exists()){
           // update room status 
             remove(roomRef).then(() => {
             props.goToPage("TicTacToeLobby");
             setLoading(false);
             });
           //
      }else{
        setLoading(false);
        props.goToPage("TicTacToeLobby");
      }
    });
  };
  
  
  //boxes click
  const box0 = (e) => {
    if( room && room.curPlayer == user.uid && room.board[0] == "" && status == "match"){
    //set Img and visible
    e.target.src="/images/x.png";
    //set  visiblity 
    setBoxVisibility(pre => ({
      ...pre,
      0: true,
    }));
    //set in Database 
     update(refDb(db, "room/" + roomKey), {
     "board/0": user.uid,
     "curPlayer": room.curPlayer == room.player1 ? room.player2 : room.player1,
     });
    };
  }; // box0
  
  const box1 = (e) => {
    if( room && room.curPlayer == user.uid && room.board[1] == "" && status == "match"){
    //set Img and visible
    e.target.src="/images/x.png";
    //set  visiblity 
    setBoxVisibility(pre => ({
      ...pre,
      1: true,
    }));
    //set in Database 
     update(refDb(db, "room/" + roomKey), {
     "board/1": user.uid,
     "curPlayer": room.curPlayer == room.player1 ? room.player2 : room.player1,
     });
    };
  }; // box1
  
  const box2 = (e) => {
    if( room && room.curPlayer == user.uid && room.board[2] == "" && status == "match"){
    //set Img and visible
    e.target.src="/images/x.png";
    //set  visiblity 
    setBoxVisibility(pre => ({
      ...pre,
      2: true,
    }));
    //set in Database 
     update(refDb(db, "room/" + roomKey), {
     "board/2": user.uid,
     "curPlayer": room.curPlayer == room.player1 ? room.player2 : room.player1,
     });
    };
  }; // box2
  
  const box3 = (e) => {
    if( room && room.curPlayer == user.uid && room.board[3] == "" && status == "match"){
    //set Img and visible
    e.target.src="/images/x.png";
    //set  visiblity 
    setBoxVisibility(pre => ({
      ...pre,
      3: true,
    }));
    //set in Database 
     update(refDb(db, "room/" + roomKey), {
     "board/3": user.uid,
     "curPlayer": room.curPlayer == room.player1 ? room.player2 : room.player1,
     });
    };
  }; // box3
  
  const box4 = (e) => {
    if( room && room.curPlayer == user.uid && room.board[4] == "" && status == "match"){
    //set Img and visible
    e.target.src="/images/x.png";
    //set  visiblity 
    setBoxVisibility(pre => ({
      ...pre,
      4: true,
    }));
    //set in Database 
     update(refDb(db, "room/" + roomKey), {
     "board/4": user.uid,
     "curPlayer": room.curPlayer == room.player1 ? room.player2 : room.player1,
     });
    };
  }; // box4
  
  const box5 = (e) => {
    if( room && room.curPlayer == user.uid && room.board[5] == "" && status == "match"){
    //set Img and visible
    e.target.src="/images/x.png";
    //set  visiblity 
    setBoxVisibility(pre => ({
      ...pre,
      5: true,
    }));
    //set in Database 
     update(refDb(db, "room/" + roomKey), {
     "board/5": user.uid,
     "curPlayer": room.curPlayer == room.player1 ? room.player2 : room.player1,
     });
    };
  }; // box5
  
  const box6 = (e) => {
    if( room && room.curPlayer == user.uid && room.board[6] == "" && status == "match"){
    //set Img and visible
    e.target.src="/images/x.png";
    //set  visiblity 
    setBoxVisibility(pre => ({
      ...pre,
      6: true,
    }));
    //set in Database 
     update(refDb(db, "room/" + roomKey), {
     "board/6": user.uid,
     "curPlayer": room.curPlayer == room.player1 ? room.player2 : room.player1,
     });
    };
  }; // box6
  
  const box7 = (e) => {
    if( room && room.curPlayer == user.uid && room.board[7] == "" && status == "match"){
    //set Img and visible
    e.target.src="/images/x.png";
    //set  visiblity 
    setBoxVisibility(pre => ({
      ...pre,
      7: true,
    }));
    //set in Database 
     update(refDb(db, "room/" + roomKey), {
     "board/7": user.uid,
     "curPlayer": room.curPlayer == room.player1 ? room.player2 : room.player1,
     });
    };
  }; // box7
  
  const box8 = (e) => {
    if( room && room.curPlayer == user.uid && room.board[8] == "" && status == "match"){
    //set Img and visible
    e.target.src="/images/x.png";
    //set  visiblity 
    setBoxVisibility(pre => ({
      ...pre,
      8: true,
    }));
    //set in Database 
     update(refDb(db, "room/" + roomKey), {
     "board/8": user.uid,
     "curPlayer": room.curPlayer == room.player1 ? room.player2 : room.player1,
     });
    };
  }; // box8
  
  
  //OpenMenu
  const openMenu = () => {
    setMenu(true);
  };
  
  const closeMenu = () => {
    setMenu(false)
  };
  
  const leaveMatch = () => {
    props.goToPage("TicTacToeLobby");
    setLoading(false);
  };
  
  return(
    <>
{/*background (start)*/}
      <div id={style.bg}>
       <div id={style.top}>
        <div>
          <img style={{border: user && room && room.curPlayer == user.uid && "2px solid darkcyan"}} className={style.dp} src="/images/x.png" />
          You
          <p style={{display: user && curPlayer != user.uid && "none", color: timmer <= 5 && "red"}} className={style.timmer}>{`${timmer}s`}</p>
        </div>
        <div>
          <img style={{border: user && room && room.curPlayer != user.uid && "2px solid darkcyan"}} className={style.dp} src="/images/o.png" />
          {opponent.name}
          <p style={{display: user && curPlayer == user.uid && "none", color: timmer <= 5 && "red"}} className={style.timmer}>{`${timmer}s`}</p>
        </div>
       </div>
{/*board (start)}*/}
       <div id={style.boardLinear}>
       <div id={style.board}>
{/*linear (start)*/}
        <div className={style.columnLinear}>
        
          <div className={style.boxes}>
            <img style={{opacity:       boxVisiblilty[0]
              ? "1" : "0"}}
              onClick={box0}
              src={board[0]}/>
          </div>
          
          <div className={style.boxes}>
            <img src={board[1]}  style={{opacity: boxVisiblilty[1]
              ? "1" : "0"}}
              onClick={box1}/>
          </div>
          
          <div className={style.boxes}>
            <img src={board[2]}  style={{opacity: boxVisiblilty[2]
              ? "1" : "0"}}
              onClick={box2}/>
          </div>
          
        </div>
{/**/}
        <div className={style.columnLinear}>
        
          <div className={style.boxes}>
            <img src={board[3]}  style={{opacity: boxVisiblilty[3]
              ? "1" : "0"}}
              onClick={box3}/>
          </div>
          
          <div className={style.boxes}>
           <img src={board[4]}  style={{opacity: boxVisiblilty[4]
             ? "1" : "0"}}
             onClick={box4}/>
          </div>
          
          <div style={{color: "blue"}} className={style.boxes}>
             <img src={board[5]}  style={{opacity: boxVisiblilty[5]
               ? "1" : "0"}}
               onClick={box5}/>
               
          </div>
        </div>
{/**/}
       <div className={style.columnLinear}>
       
          <div className={style.boxes}>
            <img src={board[6]}  style={{opacity: boxVisiblilty[6]
              ? "1" : "0"}}
              onClick={box6}/>
          </div>
          
          <div className={style.boxes}>
            <img src={board[7]}  style={{opacity: boxVisiblilty[7]
              ? "1" : "0"}}
              onClick={box7}/>
          </div>
          
          <div className={style.boxes}>
            <img src={board[8]}  style={{opacity: boxVisiblilty[8]
              ? "1" : "0"}}
              onClick={box8}/>
          </div>
          
        </div>
{/*linear (end)*/}
       </div>
       </div>
{/*board (end)}*/}
       <div id={style.bottom}>
         <i onClick={openMenu} className="fa-solid fa-bars"></i>
         <i className="fa-solid fa-circle-info"></i>
       </div>
       
{/*Alert Message (start)*/}
       { alertMsg.show == true && <div id={style.alertMsg}>
         <div id={style.alertBox}
              style={{color: alertMsg.color}}>
          <p id={style.alertMsgTitle}>{alertMsg.title}</p>
          <i className={alertMsg.sign}></i>
          <p id={style.alertMsgMessage}>{alertMsg.msg}</p>
          <button style={{backgroundColor: alertMsg.color}} onClick={alertMsgBtnClicked}>Okay</button>
         </div>
       </div> }
{/*Alert Message (start)*/}
{/*starting splash (start)*/}
       { startingSplash.show && <div id={style.startingSplash}>
         <div>
          <p>{startingSplash.text}</p>
         </div>
       </div>}
{/*starting splash (start)*/}
{/*menu (start)*/}
       { menu && <div id={style.menu}>
        <div id={style.menuBox}>
         <div id={style.menuHeader}>
         <div></div>
         </div>
         
         <div className={style.menuColumns}>
          <img className={style.menuProfilePictures} src={userData?.profilePicture}/>
          <div className={style.menuLinears}>
          <h1>{userData.name}</h1>
          <p>Playing as X</p>
         </div>
         <div className={style.rankDiv}>
            <img
              className={style.rankImg}
              src={
                userData.stars < 30
                  ? "/images/bronzebadge.png"
                  : userData.stars < 50
                  ? "/images/silverbadge.png"
                  : userData.stars < 100
                  ? "/images/goldbadge.png"
                  : "/images/dimondbadge.png"
              }
              alt="Badge"
            />
            <div>
              <i className="fa-solid fa-star"></i>
              <p>{userData.stars}</p>
            </div>
          </div>
         </div>
         
         <div className={style.menuColumns}>
         <img className={style.menuProfilePictures} src={opponent?.profilePicture}/>
          <div className={style.menuLinears}>
          <h1>{opponent.name}</h1>
          <p>Playing as O</p>
         </div>
         <div className={style.rankDiv}>
            <img
              className={style.rankImg}
              src={
                opponent.stars < 30
                  ? "/images/bronzebadge.png"
                  : opponent.stars < 50
                  ? "/images/silverbadge.png"
                  : opponent.stars < 100
                  ? "/images/goldbadge.png"
                  : "/images/dimondbadge.png"
              }
              alt="Badge"
            />
            <div>
              <i className="fa-solid fa-star"></i>
              <p>{opponent.stars}</p>
            </div>
          </div>
         </div>
         <div id={style.btnsDiv} className={style.menuColumns}>
          <button onClick={leaveMatch} id={style.btn1}>Leave Match</button>
          <button onClick={closeMenu} id={style.btn2}>Close</button>
         </div>
        </div>
       </div>}
{/*menu (end)*/}
      </div>
{/*background (end)*/}
     {loading && <Loading /> }
    </>
    );
};

export default TicTacToe;