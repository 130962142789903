import React from "react";
import style from "./css/Toast.module.css";

function Toast(props){
  return(
    <>
    <div id={style.toast} onClick={props.toastShow} style={{backgroundColor: props.color}}>
      {props.msg}
      <i className="fa-solid fa-circle-xmark"></i>
      </div>
  </>
)
}

export default Toast;