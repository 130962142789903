//imports  
import React, {useEffect, useState} from "react";
import style from "./css/EditProfile.module.css"; 
import { auth, db } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { get, set, update, ref as refDb, onValue } from "firebase/database";
import Loading from "./Loading.js"; //componets

function EditProfile(props){
  //react hooks and variables
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [editedData, setEditedData] = useState({});
  
  //functions
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (detectedUser) => {
      if(detectedUser){
        setUser(detectedUser);
        get(refDb(db, "user/" + detectedUser.uid)).then((snapshot) => {
          if(snapshot.exists()){
            setUserData(snapshot.val());
            setEditedData({
              name: snapshot.val().name,
              profilePicture: snapshot.val().profilePicture,
            });
          };
        });
      };
    });
  }, []);
  
  const goBack = () => {
    props.editProfileView(false);
  };
  
  const save = async () => {
    setLoading(true);
    await update(refDb(db, "user/" + user.uid), {
      name: editedData.name,
      profilePicture: editedData.profilePicture,
    });
    setLoading(false);
    goBack();
  };
  
  const changeProfilePic = (e) => {
    setEditedData(pre => ({
      ...pre,
      profilePicture: e.target.getAttribute("url"),
    }));
  };
  
  const changeName = (e) => {
    if(e.target.value.trim() != "" && e.target.value.length <= 10){
    setEditedData(pre => ({
      ...pre,
      name: e.target.value.trim(),
    }));
    };
  };
  
  //return
  return(
    <>
     <div id={style.bg}>
       <div id={style.box}>
        <div id={style.header}>
          <p>Edit Profile</p>
          <i onClick={goBack} className="fa-solid fa-xmark"></i>
        </div>
        <div className={style.linear}>
         <img id={style.mainProfilePicture} src={editedData?.profilePicture} />
        </div>
        <p style={{marginLeft: "15px"}} className={style.miniTitle}>Set your Profile picture</p>
        <div id={style.horizontalScrollview}>
           <div id={style.scrollDiv}>
           <div>
            <img onClick={changeProfilePic} src="./images/user.png" url="./images/user.png"/>
            None
           </div>
           <div>
            <img onClick={changeProfilePic} src="./images/user3.png" url="./images/user3.png"/>
            Het
           </div>
           <div>
            <img onClick={changeProfilePic} src="./images/user4.png" url="./images/user4.png"/>
            Aman
           </div>
           <div>
            <img onClick={changeProfilePic} src="./images/user5.png" url="./images/user5.png"/>
            Jai
           </div>
           <div>
            <img onClick={changeProfilePic} src="./images/user2.png" url="./images/user2.png"/>
            Netaji
           </div>
           </div>
         </div>
         <div id={style.inputDiv}>
          <p className={style.miniTitle}>Set your name</p>
           <input maxLength="10" onChange={changeName} defaultValue={userData?.name} type="text" placeholder="Enter Your Name"/>
         </div>
         <div id={style.btnsDiv}>
           <button onClick={goBack} id={style.btn1}>
               Back
              </button>
              <button onClick={save} id={style.btn2}>
               Save
              </button>
         </div>
       </div>
     </div>
     {loading && <Loading/>}
    </>
    );
};
export default EditProfile;