//imports
import React, { useState } from "react";
import style from "./css/Navbar.module.css";
//

function Navbar(props) {
  // variables and hooks
  const [curNav, setCurNav] = useState("Home");
  
  // functions
  const navClick = (e) => {
    props.goToNav(e.currentTarget.id);
    setCurNav(e.currentTarget.id);
  };
  
  return(
    <>
     <div id={style.bg}>
      <div onClick={navClick} id="Leaderboard" className={style.btns}>
      <i className={`fa-solid fa-ranking-star ${style.icons}`} style={{color: curNav == "Leaderboard" ? "black" : "gray"}} ></i>
      <p>Rankboard</p>
      </div>
      
      <div onClick={navClick} id="Notification"  className={style.btns}>
      <i className={`fa-solid fa-bell ${style.icons}`}style={{color: curNav == "Notification" ? "black" : "gray"}} ></i>
      <p>Notification</p>
      </div>
      
      <div onClick={navClick} id="Home" className={style.btns}>
      <img id={style.btn1} src="/images/ezexabtns(1).png"/>
      </div>
      
      <div onClick={navClick} id="Refer" className={style.btns}>
      <i className={`fa-solid fa-link ${style.icons}`} style={{color: curNav == "Refer" ? "black" : "gray"}} ></i>
      <p>Referal</p>
      </div>
      
      <div onClick={navClick} id="Settings" className={style.btns}>
      <i className={`fa-solid fa-gear ${style.icons}`} style={{color: curNav == "Settings" ? "black" : "gray"}} ></i>
      <p>Settings</p>
      </div>
      
     </div>
    </>
    );
};
export default Navbar;