//imports 
import React, { useState, useEffect, useRef } from "react";
import style from "./css/Home.module.css";
import { auth, db } from "../firebase";
import { onValue, get, set, ref as refDb } from "firebase/database";
import { onAuthStateChanged, signOut } from "firebase/auth";
import Toast from "./Toast.js"; // components
import Loading from "./Loading.js" // components 
import HomeSplash from "./HomeSplash.js"; // components
import Ec from "./Ec.js";
import DailyRewards from "./DailyRewards.js"; // components
import Footer from "./Footer.js"; //components
import LevelUpRewards from "./LevelUpRewards.js"; //components
import SeasonRewards from "./SeasonRewards.js" //components
import Drower from "./Drower.js"; //components
import SliderNav from "./SliderNav.js"; //components

// signOut(auth)

function Home(props){
  //
  //variables and react hooks
  //
  const rs = "₹";
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [userRuppee, setUserRuppee] = useState(0);
  const [toast, setToast] = useState(false);
  const [toastData, setToastData] =useState({});
  const [homeSplash, setHomeSplash] = useState(true);
  const contentDivRef = useRef(null);
  const [drower, setDrower] = useState(false);
  //
  //functions
  //
  //
  //function after use Detected
  //
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (detectedUser) => {
      if(detectedUser){
        setUser(detectedUser);
        const userDataSnapshot = await get(refDb(db, "user/" + detectedUser.uid))
        if(userDataSnapshot.exists()){
         setUserData(userDataSnapshot.val());
         setUserRuppee(userDataSnapshot.val().ruppee)
        setHomeSplash(false);
        };
      };
    });
    return() => {
      unsubscribe();
    };
  }, []);
  //
  useEffect(() => {
    //
    if(user){
      const unsubscribe2 = onValue(refDb(db, "user/" + user.uid + "/ruppee/"), (snapshot) => {
        setUserRuppee(snapshot.val());
      });
      return() => {
        unsubscribe2()
      }};
  }, [user])
  //
  const aferUpdateAlert = () => {
    setToastData({
      color: "darkcyan",
      msg: "This game is currently in development and will be available after the update.",
    });
    setToast(true);
  };
  //
  const goToPage = (e) => {
    props.goToPage(e.currentTarget.getAttribute("clickpage"), e.currentTarget.getAttribute("story-number"));
  };
  //
  const toastShow = () => {
    setToast(!toast);
  };
  //
  const drowerShow = () => {
    setDrower(!drower);
  }

  return(
    <>
    <div id={style.bg}>
    {/*toolbar (start)*/}
     <div id={style.toolbar}>
      <div className={style.linear}>
      <i onClick={drowerShow} id={style.menuBtn} className="fa-solid fa-bars"></i>
      <div id={style.rankBadge}>
       <img clickpage="Rankbadge" onClick={goToPage} src={
            (userData.stars < 30) ? "/images/bronzebadge.png" : 
            (userData.stars < 50) ? "/images/silverbadge.png" : 
            (userData.stars < 100) ? "/images/goldbadge.png" :
                  "/images/dimondbadge.png"
               } />
      </div>
      <img id={style.logo} src="./images/event.png"/>
      </div>
      <div className={style.linear}>
      <div id={style.wallet} >
      <div>
        {`${rs} ${userRuppee ? userRuppee.toFixed(1) : 0}`}
      </div>
      <i className="fa-solid fa-wallet"></i>
      </div>
     </div>
     </div>
     {/*toolbar (end)*/}
     {/*content box (start)*/}
     { homeSplash ? <HomeSplash /> :
     <div id={style.content}>
      {/*bigBanner (start)*}
      <div className={style.cover}>
       <div id={style.bigBanner}>
        <DailyRewards />
       </div>
      </div>
      {/*bigBanner (end)*/}
      {/*stories (start)*/}
     <div className={style.horizontalScrollBar}>
       <div className={style.scroll}>
       <div className={style.storiesDiv}
            story-number="1"
            clickpage="Story"
            onClick={goToPage}>
       <div className={style.storiesBg}>
        <img className={style.stories} src="/images/storybanner1.jpg" />
        <div>&#x2B50;</div>
        </div>
        Winner
        </div>
        <div className={style.storiesDiv}
             story-number="2"
             clickpage="Story"
             onClick={goToPage}>
        <div className={style.storiesBg}>
        <img className={style.stories} src="/images/storybanner2.jpg" />
        <div>&#x2B50;</div>
        </div>
        Referal
        </div>
        <div className={style.storiesDiv}
             story-number="3"
             clickpage="Story"
             onClick={goToPage}>
        <div className={style.storiesBg}>
        <img className={style.stories} src="./images/spacialBanner1.jpg" />
        </div>
        Game
        </div>
        <div className={style.storiesDiv}
             story-number="4"
             clickpage="Story"
             onClick={goToPage}>
        <div className={style.storiesBg}>
        <img className={style.stories} src="/images/game3.jpg" />
        </div>
        new game
        </div>
        <div className={style.storiesDiv}
             story-number="5"
             clickpage="Story"
             onClick={goToPage}>
        <div className={style.storiesBg}>
        <img className={style.stories} src="/images/game2.jpg" />
        </div>
        new game
        </div>
       </div>
      </div>
     {/*stories (end)*/}
      {/*spacial (start)*/}
      
       <div id={style.spacialDiv}>
        <div className={style.spacialBoxes}>
         <img src="./images/spacialBanner1.jpg"/>
         <div>
         <h1>Play</h1>
         <p>Free Entry!</p>
         <span style={{color: "blue"}}> <i className="fa-solid fa-gamepad"></i> E-Zexa Cards</span>
         </div>
        </div>
        <div className={style.spacialBoxes} style={{backgroundColor: "#ff535322"}}>
         <img clickpage="Ec" onClick={goToPage} src="./images/spacialBanner2.jpg"/>
         <div>
         <h1>Visit</h1>
         <p>Buy & Sell!</p>
         <span style={{color: "red"}}> <i className="fa-solid fa-money-bill"></i> E-Zexa Cash</span>
         </div>
        </div>
       </div>
      {/*spacial (end)*/}
      {/*newGames (start)*/}
      <div className={style.headings}>
       <p>New Games</p>
       <div></div>
       <i>About</i>
      </div>
      <div className={style.horizontalScrollBar}>
      <div className={style.scroll}>
        <div clickpage="TicTacToeLobby" onClick={goToPage} className={style.games}>
         <div className={style.tags}>
           New
         </div>
         <img src="/images/game1.jpg" />
          Tic Tac Toe
        </div>
        <div clickpage="CardEW" onClick={goToPage} className={style.games}>
         <div className={style.tags}>
           Card
         </div>
         <img src="/images/game2.jpg" />
          Joker
        </div>
        <div clickpage="SkillCrushLobby" onClick={goToPage} className={style.games}>
         <div className={style.tags}>
           Hot
         </div>
         <img src="/images/game3.jpg" />
          SC Lite
        </div>
        <div className={style.games}>
        <div className={style.tags}>
           Card
         </div>
         <img src="/images/game5.jpg" />
          Zombie Blood
        </div>
       </div>
      </div>
      {/*newGames (end)*/}
      {/*Upcomming (start)*/}
      <div className={style.headings}>
       <p>Upcomming Games</p>
       <div></div>
       <i>About</i>
      </div>
      <div className={style.horizontalScrollBar}>
      <div className={style.scroll}>
        <div className={style.games}>
         <img src="/images/game5.jpg" />
          Tic Tac Toe
        </div>
        <div className={style.games}>
         <img src="/images/game3.jpg" />
          Joker
        </div>
        <div className={style.games}>
         <img src="/images/game1.jpg" />
          SC Lite
        </div>
        <div className={style.games}>
         <img src="/images/game4.jpg" />
          Zombie Blood
        </div>
       </div>
      </div>
      {/*Upcomming (end)*/}
      {/*spacial (start)*/}
      <div className={style.headings}>
       <p>Spacial</p>
       <div></div>
       <i>About</i>
       </div>
       <div id={style.spacialDiv}>
        <div className={style.spacialBoxes}>
         <img src="./images/spacialBanner1.jpg"/>
         <div>
         <h1>Play</h1>
         <p>Free Entry!</p>
         <span style={{color: "blue"}}> <i className="fa-solid fa-gamepad"></i> E-Zexa Cards</span>
         </div>
        </div>
        <div className={style.spacialBoxes} style={{backgroundColor: "#ff535322"}}>
         <img src="./images/spacialBanner2.jpg"/>
         <div>
         <h1>Visit</h1>
         <p>Buy & Sell!</p>
         <span style={{color: "red"}}> <i className="fa-solid fa-money-bill"></i> E-Zexa Cash</span>
         </div>
        </div>
       </div>
      {/*spacial (end)*/}
     </div> }
     {/*content box (end)*/}
    </div>
    { drower && <Drower drowerShow={drowerShow} goToPage={props.goToPage}/>}
    { toast && <Toast msg={toastData.msg} color={toastData.color} toastShow={toastShow} /> }
    { loading && <Loading /> }
    </>
    );
};
export default Home;