//imports 
import React, { useEffect, useState } from "react";
import style from "./css/TttGameMaking.module.css";
import { auth, db } from "../firebase.js";
import { onAuthStateChanged }  from "firebase/auth";
import { ref as refDb, set, get, onValue, remove, push, onDisconnect, update } from "firebase/database";
import Loading from "./Loading.js";
 
function TttGameMaking(props){
  //
  // variables and react hooks
  //
  const [user, setUser] = useState(null);
  const [player2, setPlayer2] = useState("Searching...");
  const [player2Data, setPlayer2Data] = useState({});
  const [userData, setUserData] = useState({});
  const [roomCreated, setRoomCreated] = useState(false);
  const [roomId, setRoomId] = useState(props.gameItems);
  const [loading, setLoading] = useState(false);
  //
  // functions
  //
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (detectedUser) => {
      if(detectedUser){
        setUser(detectedUser);
      };
    });
    return unsubscribe;
  }, [])
  //waiting list 
  useEffect(() => {
    if(user){
      const unsubscribe = onValue(refDb(db, "user/" + user?.uid), (snapshot) => {
        if(snapshot.exists()){
        setUserData(snapshot.val());
        };
      })
    };
  }, [user]);
  //
  useEffect(() => {
    if(userData && user){
      //Connection Players 
    const unsubscribe = onValue(refDb(db, "room/" + roomId), (snapshot) => {
      //
      const room = snapshot.val();
      //
      if(snapshot.exists()){
        if(room.player2 != ""){
          //set loadding and afer 3s go to Main Game!
          setPlayer2(
            room.player2 === user?.uid ?
            room.player1Data.name :
            room.player2Data.name);
            //
            setPlayer2Data(room.player2 === user?.uid ? room.player1Data : room.player2Data)
          //set Time Out 
          setTimeout(function() {
            props.goToPage("TicTacToe", roomId);
          }, 3000);
          //
        }else{
         if(room.player1 != user.uid){
          // onDisconnect(refDb(db, "room/" + roomId )).update({status: 'player2-left'})
           update(refDb(db, "room/" + roomId),{
             player2: user.uid,
             player2Data: userData,
             curPlayer: room.curPlayer == "player2" ? user?.uid : room.player1,
           });
         };
        }};
    });
    //
    //return
    return () => unsubscribe();
   };
  }, [userData]);
      
  //

  
   const goBack = () => {
     //  working...
   };
  //
  //
  return(
    <>
     <div id={style.bg}>
       <div id={style.header}>
         <i className="fa-solid fa-chevron-left" onClick={goBack}></i>
         Back
       </div>
       <div id={style.body}>
         <div className={style.linear}>
           <img src={userData.profilePicture? userData.profilePicture : "./images/user.png"}/>
           <p>You</p>
         </div>
         <img id={style.vsImg} src="/images/vs.png" />
         <div className={style.linear}>
           <img src={player2Data.profilePicture ? player2Data.profilePicture : "./images/user.png"}/>
           <p>{player2}</p>
         </div>
         <div id={style.roomKeyDiv}>
            <i className="fa-solid fa-home"></i>
            {roomId}
           </div>
       </div>
     </div>
     { loading && <Loading /> }
    </>
    );
};

export default TttGameMaking;